import { IRequestsState } from './RequestsTypes';

export const INITIAL_STATE: IRequestsState = {};

export default (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case 'API_REQUEST': {
      const { requestName, payload } = action;
      return {
        ...state,
        [requestName]: {
          isLoading: true,
          payload,
          response: null,
        },
      };
    }
    case 'API_REQUEST_SUCCEEDED': {
      const { requestName, payload, response } = action;
      return {
        ...state,
        [requestName]: {
          isLoading: false,
          payload,
          response,
        },
      };
    }
    case 'API_REQUEST_FAILED': {
      const { requestName, payload, response } = action;
      return {
        ...state,
        [requestName]: {
          isLoading: false,
          payload,
          response,
        },
      };
    }

    default:
      return state;
  }
};
