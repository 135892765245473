import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonNote,
  IonLabel,
  IonItem,
  IonList,
  IonButtons,
} from '@ionic/react';
import { logout } from '../../../domains/auth/AuthActions';
import { IUser } from '../../../domains/auth/AuthTypes';
import { selectUser } from '../../../domains/auth/AuthSelectors';
import { IStoreState } from '../../../types/ReduxTypes';
import HeaderCartButton from '../../components/HeaderCartButton/HeaderCartButton';

interface IProps {
  user: IUser;
  logout: () => any;
}

const screenTitle = 'Profile';

// TODO Loic: maybe null
const isUser = (o: null | IUser) => {
  if (!o) {
    return null;
  }
  if (o.username) {
    return o;
  }
};

class ProfileScreen extends PureComponent<IProps> {
  render() {
    const { user, logout } = this.props;
    const maybeuser = isUser(user);

    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonTitle>{screenTitle}</IonTitle>
            <IonButtons slot="end">
              <HeaderCartButton />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonHeader collapse="condense">
            <IonToolbar>
              <IonTitle size="large">{screenTitle}</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonList>
            <br />
            <IonItem lines="none">
              <IonLabel>Username</IonLabel>
              <IonNote slot="end" color="dark">
                <IonLabel>
                  <b>{maybeuser?.username}</b>
                </IonLabel>
              </IonNote>
            </IonItem>
            <IonItem lines="none">
              <IonLabel>Email</IonLabel>
              <IonNote slot="end" color="dark">
                <IonLabel>
                  <b>{maybeuser?.email}</b>
                </IonLabel>
              </IonNote>
            </IonItem>
            <IonItem lines="none">
              <IonLabel>Provider</IonLabel>
              <IonNote slot="end" color="dark">
                <IonLabel>
                  <b>{maybeuser?.provider}</b>
                </IonLabel>
              </IonNote>
            </IonItem>
            <IonItem lines="none">
              <IonLabel>Account confirmed</IonLabel>
              <IonNote slot="end" color="dark">
                <IonLabel>
                  <b>{maybeuser?.confirmed?.toString()}</b>
                </IonLabel>
              </IonNote>
            </IonItem>
          </IonList>
          <div className="container">
            <IonButton size="small" color="dark" onClick={logout}>
              Logout
            </IonButton>
          </div>
        </IonContent>
      </IonPage>
    );
  }
}

const mapStateToProps = (state: IStoreState) => {
  const user = selectUser(state);
  return {
    user,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    logout: () => {
      dispatch(logout());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileScreen);
