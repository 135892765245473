import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonText,
  IonSpinner,
  IonList,
  IonRefresher,
  IonRefresherContent,
  IonButtons,
  IonGrid,
  IonRow,
  IonCol,
} from '@ionic/react';
import { IShops } from '../../../domains/shop/ShopTypes';
import {
  selectShop,
  selectShopState,
} from '../../../domains/shop/ShopSelectors';
import { IStoreState } from '../../../types/ReduxTypes';
import { apiRequest } from '../../../api/ApiActions';
import ShopItem from '../../components/ShopItem/ShopItem';
import styles from './ShopsScreen.module.scss';
import HeaderCartButton from '../../components/HeaderCartButton/HeaderCartButton';

interface IProps {
  shops: IShops;
  fetchShops: (stopRefreshIndicator?: Function) => any;
  loading: boolean;
  errorMessage: string;
}

const screenTitle = 'Shops';

class ShopsScreen extends PureComponent<IProps> {
  componentDidMount() {
    this.props.fetchShops();
  }

  resresh = (e: any) => {
    this.props.fetchShops(e.detail.complete);
  };

  navigateToShop = (shopdId: number) => {
    // this.props.fetchShops(shopdId);
  };

  render() {
    const { shops, loading, errorMessage } = this.props;

    const showLoader = loading;
    const showErrorMessage = !showLoader && !!errorMessage;
    const showList = !showLoader && !showErrorMessage;

    // TODO Loic: make modalProduct aquals to "null" or "IProduct", and use it in the view
    // const castedProduct = modalProduct as IProduct;

    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            {/* <IonButtons slot="start">
              <IonBackButton defaultHref="/tab1" />
            </IonButtons> */}
            <IonTitle>{screenTitle}</IonTitle>

            <IonButtons slot="end">
              <HeaderCartButton />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonHeader collapse="condense">
            <IonToolbar>
              <IonTitle size="large">{screenTitle}</IonTitle>
            </IonToolbar>
          </IonHeader>

          <IonRefresher slot="fixed" onIonRefresh={this.resresh}>
            <IonRefresherContent />
          </IonRefresher>

          {!showList && (
            <div className="container">
              {showLoader && <IonSpinner />}
              {showErrorMessage && (
                <IonText color="danger">{errorMessage}</IonText>
              )}
            </div>
          )}
          {showList && (
            <IonList style={{ backgroundColor: 'transparent' }}>
              {/* <IonListHeader>
                <IonText>
                <h2>Main courses</h2>
                </IonText>
              </IonListHeader> */}
              <IonGrid className="ion-no-padding">
                <IonRow>
                  {shops?.map((shop) => {
                    return (
                      <IonCol
                        key={shop.id}
                        className="ion-no-padding"
                        sizeLg="3"
                        sizeMd="4"
                        sizeSm="6"
                        sizeXs="12"
                      >
                        <ShopItem shop={shop} />
                      </IonCol>
                    );
                  })}
                </IonRow>
              </IonGrid>
            </IonList>
          )}
        </IonContent>
      </IonPage>
    );
    // );
  }
}

const mapStateToProps = (state: IStoreState, ownProps: IProps) => {
  const { shops, isShopsLoading, shopsErrorMessage } = selectShopState(state);
  return {
    shops,
    loading: isShopsLoading,
    errorMessage: shopsErrorMessage,
  };
};

const mapDispatchToProps = (dispatch: any, ownProps: IProps) => {
  return {
    fetchShops: (stopRefreshIndicator?: Function) => {
      dispatch(
        apiRequest('fetchShops', {
          stopRefreshIndicator,
        })
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopsScreen);
