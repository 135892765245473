import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonRefresherContent,
  IonRefresher,
  IonList,
  IonText,
  IonButton,
  IonIcon,
  IonCard,
  IonCardContent,
  IonImg,
  IonItem,
  IonAvatar,
  IonBadge,
  IonRow,
  IonButtons,
} from '@ionic/react';
import { IStoreState } from '../../types/ReduxTypes';
import { apiRequest } from '../../api/ApiActions';
import {
  selectOrders,
  selectShopState,
} from '../../domains/shop/ShopSelectors';
import { IOrders } from '../../domains/shop/ShopTypes';
import {
  basket,
  checkmark,
  closeSharp,
  hourglassOutline,
} from 'ionicons/icons';
import HeaderCartButton from '../components/HeaderCartButton/HeaderCartButton';

interface IProps {
  fetchOrders: (stopRefreshIndicator?: Function) => any;
  orders: IOrders;
}

const screenTitle = 'Orders';

class Orders extends PureComponent<IProps> {
  componentDidMount() {
    this.props.fetchOrders();
  }

  resresh = (e: any) => {
    this.props.fetchOrders(e.detail.complete);
  };

  render() {
    const { orders } = this.props;
    const noOrders = !orders.length;

    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonTitle>{screenTitle}</IonTitle>
            <IonButtons slot="end">
              <HeaderCartButton />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonHeader collapse="condense">
            <IonToolbar>
              <IonTitle size="large">{screenTitle}</IonTitle>
            </IonToolbar>
          </IonHeader>

          <IonRefresher slot="fixed" onIonRefresh={this.resresh}>
            <IonRefresherContent />
          </IonRefresher>
          <IonList style={{ backgroundColor: 'transparent' }}>
            {/* <IonListHeader>We have products that fit</IonListHeader> */}
            {orders?.map(
              (order) =>
                // @ts-ignore
                console.log(order) || (
                  <IonCard key={order.id}>
                    <IonRow>
                      <IonCardContent style={{ flex: 1 }}>
                        <IonText>{order.shop.name}</IonText>
                        <br />
                        <IonText>
                          <b>{order.cart?.amount}$</b>
                        </IonText>
                        <IonText>
                          <p>
                            {moment(order.created_at).format(
                              'MMMM Do YYYY, h:mm a'
                            )}
                          </p>
                        </IonText>
                      </IonCardContent>
                      <IonCardContent>
                        <div className="">
                          {!order.validated && !order.declined && (
                            <IonIcon icon={hourglassOutline} />
                          )}
                          {!!order.validated && !order.ready && (
                            <IonBadge
                              color="white"
                              style={{
                                marginRight: '6px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                border: '1px solid #7CFC00',
                              }}
                            >
                              <IonIcon icon={checkmark} color="success" />
                              <IonText
                                style={{ fontWeight: '400', marginLeft: '4px' }}
                                color="success"
                              >
                                Validated
                              </IonText>
                            </IonBadge>
                          )}
                          {!!order.ready && !order.pickedUp && (
                            <IonBadge
                              color="white"
                              style={{
                                marginRight: '6px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                border: '1px solid #7CFC00',
                              }}
                            >
                              <IonIcon icon={checkmark} color="success" />
                              <IonText
                                style={{ fontWeight: '400', marginLeft: '4px' }}
                                color="success"
                              >
                                Ready
                              </IonText>
                            </IonBadge>
                          )}
                          {!!order.pickedUp && (
                            <IonBadge
                              color="white"
                              style={{
                                marginRight: '6px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                border: '1px solid #7CFC00',
                              }}
                            >
                              <IonIcon icon={checkmark} color="success" />
                              <IonText
                                style={{ fontWeight: '400', marginLeft: '4px' }}
                                color="success"
                              >
                                Picked up
                              </IonText>
                            </IonBadge>
                          )}
                          {!!order.declined && (
                            <IonBadge color="danger">
                              <IonIcon icon={closeSharp} />
                            </IonBadge>
                          )}
                        </div>
                      </IonCardContent>
                    </IonRow>

                    {order.cart.menusSelections.map(({ id, menu }) => {
                      return (
                        <IonItem key={id}>
                          <IonAvatar className="ion-margin-end">
                            <IonImg
                              // src={`${environment.API_ENDPOINT}${product.picture?.url}`}
                              src={menu.picture?.url}
                              alt=""
                            />
                          </IonAvatar>
                          <IonText>
                            <p>1x {menu.name}</p>
                          </IonText>
                        </IonItem>
                      );
                    })}
                    {order.cart.productsSelections.map(
                      ({ id, product, productQuantity }) => {
                        return (
                          <IonItem key={id}>
                            <IonAvatar className="ion-margin-end">
                              <IonImg
                                // src={`${environment.API_ENDPOINT}${product.picture?.url}`}
                                src={product.picture?.url}
                                alt=""
                              />
                            </IonAvatar>
                            <IonText>
                              <p>
                                {productQuantity} x {product.name}
                              </p>
                            </IonText>
                          </IonItem>
                        );
                      }
                    )}
                  </IonCard>
                )
            )}
            {noOrders && (
              <div className="container">
                <IonText>
                  <h2>No orders</h2>
                </IonText>
                <IonButton routerLink="/cart" color="dark">
                  <IonIcon
                    icon={basket}
                    style={{ marginRight: '10px' }}
                    size="small"
                  />{' '}
                  <IonText>
                    <p>See your cart</p>
                  </IonText>
                </IonButton>
              </div>
            )}
          </IonList>
        </IonContent>
      </IonPage>
    );
  }
}

const mapStateToProps = (state: IStoreState) => {
  const orders = selectOrders(state);
  const { isCartIsLoading } = selectShopState(state);

  return {
    orders,
    isCartIsLoading,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    fetchOrders: (stopRefreshIndicator?: Function) => {
      dispatch(apiRequest('fetchOrders', { stopRefreshIndicator }));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Orders);
