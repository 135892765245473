import React, { useCallback, useEffect, useState } from 'react';
import {
  IonInput,
  IonItem,
  IonCardSubtitle,
  IonText,
  IonRow,
  IonSelect,
  IonSelectOption,
} from '@ionic/react';
import CardSection from './CardSection';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { PaymentMethod } from '@stripe/stripe-js';
import { DaysSLotsType, getDaysSlots, getTimeSlots } from './Checkout.utils';
import type { TimeSLotsType } from './Checkout.utils';

type FormValuesType = {
  address: string;
  city: string;
  zipcode: string;
  day: string;
  time: string;
};

export type OrderFormValuesType = {
  address: string;
  city: string;
  zipcode: string;
  requestedDate: string; // computed at submission
};

interface InjectedStripeProps {
  // stripe: any;
  // elements: stripe.elements.Elements | null;
}
type IProps = InjectedStripeProps & {
  submitOrder: (payload: {
    formValues: OrderFormValuesType;
    paymentMethod: PaymentMethod;
    stripeInstance: any;
    setIsLoading: Function;
  }) => any;
  amount: number;
};

const CheckoutForm: React.FC<IProps> = ({ amount, submitOrder }) => {
  const [formValues, setFormValues] = useState({
    address: '24 boulevard du dream',
    city: 'Hossegor',
    zipcode: '40150',
    day: '',
    time: '',
    requestedDate: '', // computed at submission
  });
  const [daysSlots, setDaysSlots] = useState<DaysSLotsType>([]);
  const [timeSlots, setTimeSlots] = useState<TimeSLotsType>([]);

  // const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const onChange = useCallback((fieldName, value) => {
    setFormValues((_formaValues) => ({
      ..._formaValues,
      [fieldName]: value,
    }));
  }, []);

  const handleSubmit = useCallback(
    async (event: any) => {
      try {
        event.preventDefault();

        if (elements == null || !stripe) {
          if (elements === null) {
            alert('Error: no "elements"');
          } else {
            alert('Error: "stripe" is missing');
          }
          return;
        }

        if (!formValues.day || !formValues.time) {
          alert('Please select a day and time');
          return;
        }

        const finalForm: OrderFormValuesType = {
          address: formValues.address,
          city: formValues.city,
          zipcode: formValues.zipcode,
          requestedDate: new Date(
            `${formValues.day}T${formValues.time}:00`
          ).toISOString(),
        };

        setIsLoading(true);

        // @ts-ignore
        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: 'card',
          card: elements.getElement(CardElement),
        });

        if (error || !paymentMethod) {
          console.log('error');
          console.log(error);
          alert(error?.message);
          setIsLoading(false);
          return;
        }

        submitOrder({
          formValues: finalForm,
          paymentMethod,
          stripeInstance: stripe,
          setIsLoading,
        });
      } catch (error) {
        // @ts-ignore
        alert(error?.message || JSON.stringify(error));
      } finally {
        setIsLoading(false);
      }
    },
    [elements, formValues, stripe, submitOrder]
  );

  // on mount
  useEffect(() => {
    const _daysSlots = getDaysSlots();
    setDaysSlots(_daysSlots);

    const _timeSlots = getTimeSlots();
    setTimeSlots(_timeSlots);
  }, []);

  return (
    <div>
      <IonCardSubtitle>Your order</IonCardSubtitle>
      <hr />
      <IonItem lines="none">
        <IonCardSubtitle style={{ width: '100%' }}>
          <IonText>Day</IonText>
        </IonCardSubtitle>
        <IonSelect
          value={formValues.day}
          placeholder="Select a day"
          onIonChange={(e) => {
            onChange('day', e.detail.value);
          }}
          style={{ flexShrink: 0, maxWidth: 'unset' }}
        >
          {daysSlots?.map(({ value, label }) => {
            return (
              <IonSelectOption key={value} value={value}>
                {label}
              </IonSelectOption>
            );
          })}
        </IonSelect>
      </IonItem>
      <IonItem lines="none">
        <IonCardSubtitle style={{ width: '100%' }}>
          <IonText>Hour</IonText>
        </IonCardSubtitle>
        <IonSelect
          value={formValues.time}
          placeholder="Select a time"
          onIonChange={(e) => {
            onChange('time', e.detail.value);
          }}
          style={{ flexShrink: 0, maxWidth: 'unset' }}
        >
          {timeSlots?.map(({ value, label }) => {
            return (
              <IonSelectOption key={value} value={value}>
                {label}
              </IonSelectOption>
            );
          })}
        </IonSelect>
      </IonItem>

      <br />
      <IonCardSubtitle>Your information:</IonCardSubtitle>
      <hr />
      <IonItem lines="none" className="ion-align-items-center">
        <IonCardSubtitle>
          <IonText>Adress</IonText>
        </IonCardSubtitle>
        <IonRow slot="end" className="ion-text-right ion" color="dark">
          <IonInput value={formValues.address} />
        </IonRow>
      </IonItem>

      <IonItem lines="none" className="ion-align-items-center">
        <IonCardSubtitle>
          <IonText>City</IonText>
        </IonCardSubtitle>
        <IonRow slot="end" className="ion-text-right ion" color="dark">
          <IonInput value={formValues.city} />
        </IonRow>
      </IonItem>

      <IonItem lines="none" className="ion-align-items-center">
        <IonCardSubtitle>
          <IonText>Zipcode</IonText>
        </IonCardSubtitle>
        <IonRow slot="end" className="ion-text-right ion" color="dark">
          <IonInput value={formValues.zipcode} />
        </IonRow>
      </IonItem>

      <br />
      <CardSection
        handleSubmit={handleSubmit}
        amount={amount}
        zipcode={formValues.zipcode}
        isLoading={isLoading}
      />
    </div>
  );
};

// class OLD_CheckoutForm extends React.Component<IProps, any> {
//   state = {
//     error: '',
//     formValues: {
//       address: '24 boulevard du dream',
//       city: 'La Rochelle',
//       zipcode: '17000',
//       stripe_id: '',
//     },
//   };

//   submitOrder = () => {
//     const { formValues } = this.state;

//     this.props.stripe
//       .createToken()
//       .then((res: any) => {
//         console.log("createToken res");
//         console.log(res);

//         this.props.submitOrder({
//           token: res.token.id,
//           address: formValues.address,
//           city: formValues.city,
//           zipcode: formValues.zipcode,
//         });
//       })
//       .catch((err: any) => this.setState({ error: err }));
//   };

//   render() {
//     const { formValues } = this.state;
//     const { amount } = this.props;
//     return (
//       <div>
//         <IonCardSubtitle>Your information:</IonCardSubtitle>
//         <hr />

//         <IonItem lines="none" className="ion-align-items-center">
//           <IonCardSubtitle>
//             <IonText>Adress</IonText>
//           </IonCardSubtitle>
//           <IonRow slot="end" className="ion-text-right ion" color="dark">
//             <IonInput value={formValues.address} />
//           </IonRow>
//         </IonItem>

//         <IonItem lines="none" className="ion-align-items-center">
//           <IonCardSubtitle>
//             <IonText>City</IonText>
//           </IonCardSubtitle>
//           <IonRow slot="end" className="ion-text-right ion" color="dark">
//             <IonInput value={formValues.city} />
//           </IonRow>
//         </IonItem>

//         <IonItem lines="none" className="ion-align-items-center">
//           <IonCardSubtitle>
//             <IonText>Zipcode</IonText>
//           </IonCardSubtitle>
//           <IonRow slot="end" className="ion-text-right ion" color="dark">
//             <IonInput value={formValues.zipcode} />
//           </IonRow>
//         </IonItem>

//         <br />
//         <CardSection
//           data={this.state.formValues}
//           submitOrder={this.submitOrder}
//           amount={amount}
//           stripe={null}
//           elements={null}
//         />
//       </div>
//     );
//   }
// }

export default CheckoutForm;
