import { all, takeEvery, put } from 'redux-saga/effects';
import { Dispatch, AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { pushNotificationsRegistration } from './PushNotificationsActions';
import { isPlatform } from '@ionic/react';
import OneSignal, {
  NotificationWillDisplayEvent,
  PushSubscriptionChangedState,
} from 'onesignal-cordova-plugin';
import { IStoreState } from '../../types/ReduxTypes';
import { environment } from '../../config/environments';

/**
 * OneSignal Ionic SDK:
 * https://documentation.onesignal.com/docs/mobile-sdk#observe-push-subscription-changes
 * https://documentation.onesignal.com/docs/ionic-sdk-setup
 * https://documentation.onesignal.com/docs/cordova-sdk
 */

const initializeOneSignalService: ThunkAction<
  void,
  IStoreState,
  unknown,
  AnyAction
> = () => {
  return (dispatch: Dispatch, getState: () => IStoreState) => {
    // Uncomment to set OneSignal device logging to VERBOSE
    // OneSignal.Debug.setLogLevel(6);

    // Uncomment to set OneSignal visual logging to VERBOSE
    // OneSignal.Debug.setAlertLevel(6);

    OneSignal.initialize(environment.ONESIGNAL_APP_ID);

    const myLifecyleListener = function (event: NotificationWillDisplayEvent) {
      /**
       * From the doc
       */
      event.preventDefault();

      // @ts-ignore
      event.notification.display(); // Doesn't seem to work

      /**
       * Tests
       */
      // const notification = event.getNotification();
      // console.log('OneSignal notification', notification);

      // notification.display();

      // @ts-ignore
      // event.complete(notification);

      // Use notification.display() to display the notification after some async work
    };

    OneSignal.Notifications.addEventListener(
      'foregroundWillDisplay',
      myLifecyleListener
    );

    let myClickListener = async function (event: any) {
      let notificationData = JSON.stringify(event);
      console.log('OneSignal notificationData', notificationData);
    };
    OneSignal.Notifications.addEventListener('click', myClickListener);

    // Prompts the user for notification permissions.
    //    * Since this shows a generic native prompt, we recommend instead using an In-App Message to prompt for notification permission (See step 7) to better communicate to your users what notifications they will get.
    OneSignal.Notifications.requestPermission(true).then(
      (accepted: boolean) => {
        console.log('User accepted notifications: ' + accepted);
      }
    );

    const pushSubscriptionListener = (event: PushSubscriptionChangedState) => {
      console.log('Push subscription changed: ' + event);
      const userId = event.current.id;
      const pushToken = event.current.token;
      console.log('OneSignal userId', userId);
      console.log('OneSignal pushToken', pushToken);

      dispatch(pushNotificationsRegistration(userId || '', pushToken || ''));
    };
    OneSignal.User.pushSubscription.addEventListener(
      'change',
      pushSubscriptionListener
    );

    /**
     * BEFORE
     */

    // oneSignal.startInit(
    //   // environment.ONESIGNAL_APP_ID,
    //   'c3e9cde1-fbe1-4971-bbce-4bf9fd459832',
    //   environment.ONESIGNAL_GOOGLE_SENDER_ID
    // );

    // oneSignal.iOSSettings({
    //   kOSSettingsKeyAutoPrompt: true,
    //   kOSSettingsKeyInAppLaunchURL: false,
    // });
    // oneSignal.registerForPushNotifications();
    // // The promptForPushNotificationsWithUserResponse function will show the iOS push notification prompt. We recommend removing the following code and instead using an In-App Message to prompt for notification permission (See step 6)
    // oneSignal.promptForPushNotificationsWithUserResponse().then((accepted) => {
    //   console.log('User accepted notifications: ' + accepted);
    // });

    // oneSignal.inFocusDisplaying(oneSignal.OSInFocusDisplayOption.Notification);

    // oneSignal.handleNotificationReceived().subscribe((notification) => {
    //   console.log(notification);
    // });

    // oneSignal.handleNotificationOpened().subscribe((notificationResult) => {
    //   console.log(notificationResult);
    // });

    // oneSignal.endInit();

    // oneSignal.getIds().then((ids) => {
    //   const { pushToken, userId } = ids;
    //   dispatch(pushNotificationsRegistration(userId, pushToken));
    // });
  };
};

function* appStart() {
  //   const state = yield select((state) => state);
  if (isPlatform('capacitor')) {
    // TODO Loic typescript redux-thunk action called from redux-saga "put"
    // @ts-ignore
    yield put(initializeOneSignalService());
  }
}

const pushNotificationsSagas = function* pushNotificationsSagas(): any {
  yield all([takeEvery('APP_START', appStart)]);
};

export default [pushNotificationsSagas];
