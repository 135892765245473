// import { all, takeEvery, call, put, select } from 'redux-saga/effects';
import { all, takeEvery, call, put, select } from 'typed-redux-saga';
import { loginSucceeed, loginFailed, login } from '../domains/auth/AuthActions';
import { selectUser, selectAuth } from '../domains/auth/AuthSelectors';
import { selectCartId } from '../domains/shop/ShopSelectors';
import { apiRequest, apiRequestFailed, apiRequestSucceeed } from './ApiActions';
import { apiFecth, apiPost, apiPut, apiDelete } from './ApiServices';
import { selectPushUserId } from '../domains/pushNotifications/PushNotificationsSelectors';
import { environment } from '../config/environments';
import { isSatusCodeValid } from '../utils/utils';
import { PaymentIntent } from '@stripe/stripe-js';
import { CartItemType } from '../domains/shop/ShopTypes';

const { API_ENDPOINT } = environment;

function* request(action: any) {
  const {
    requestName,
    payload,
    payload: { setIsLoading },
  } = action;

  if (requestName === 'login') {
    const url = `${API_ENDPOINT}/auth/local`;
    const headers = {
      'Content-Type': 'application/json',
    };

    const pushUserId = yield* select(selectPushUserId);
    const options = {
      identifier: payload.email,
      password: payload.password,
      pushUserId,
    };

    try {
      yield put(login());

      const result = yield* call(apiPost, url, headers, options);
      const response = yield* call(() => result.json());

      if (response?.user?.id || isSatusCodeValid(response.statusCode)) {
        yield* put(loginSucceeed(response));
        yield* put(apiRequest('fetchCart'));
      } else {
        const message = response.message[0].messages[0].message || '';
        yield* put(loginFailed({ message }));
      }
    } catch (error) {
      // @ts-ignore
      yield* put(loginFailed({ message: error?.message }));
    }
  } else if (requestName === 'loginSocial') {
    const url = `${API_ENDPOINT}/auth/${payload.provider}/callback${payload.search}`;
    const headers = {};

    try {
      yield* put(login());

      const result = yield* call(apiFecth, url, headers);
      const response = yield* call(() => result.json());

      if (response?.user?.id) {
        yield put(loginSucceeed(response));
      } else {
        const message = response.message[0].messages[0].message || '';
        yield put(loginFailed({ message, isSocial: true }));
      }
    } catch (error) {
      // @ts-ignore
      yield put(loginFailed({ message: error?.message }));
    }
  } else if (requestName === 'register') {
    const url = `${API_ENDPOINT}/auth/local/register`;
    const headers = {
      'Content-Type': 'application/json',
    };

    const pushUserId = yield* select(selectPushUserId);

    const options = {
      username: payload.username,
      email: payload.email,
      password: payload.password,
      pushUserId, // TODO not handled yet server-side
    };

    try {
      yield put(login());

      const result = yield* call(apiPost, url, headers, options);
      const response = yield* call(() => result.json());

      if (response?.user?.id) {
        yield put(loginSucceeed(response));
      } else {
        const message = response.message[0].messages[0].message || '';
        yield put(loginFailed({ message }));
      }
    } catch (error) {
      // @ts-ignore
      yield put(loginFailed(error?.message));
    }
  } else if (requestName === 'fetchShops') {
    const { jwt } = yield* select(selectAuth);

    const url = `${API_ENDPOINT}/shops`;
    const headers = {
      Authorization: `Bearer ${jwt}`,
    };

    try {
      const result = yield* call(apiFecth, url, headers);
      const response = yield* call(() => result.json());

      console.log('response');
      console.log(response);

      if (Array.isArray(response)) {
        yield put(apiRequestSucceeed({ requestName, response, url }));
      } else {
        yield put(
          apiRequestFailed({
            requestName,
            response,
            errorMessage: response.message,
            url,
          })
        );
      }
    } catch (error) {
      yield put(
        apiRequestFailed({
          // @ts-ignore
          errorMessage: error?.message,
          requestName,
          response: {},
          url,
          payload: {},
        })
      );
    }
  } else if (requestName === 'fetchShopMenusAndProducts') {
    const { shopId } = payload;
    const url = `${API_ENDPOINT}/products?shop=${shopId}`;

    const { jwt } = yield* select(selectAuth);
    const headers = {
      Authorization: `Bearer ${jwt}`,
    };

    try {
      // Fetch Products
      const result = yield* call(apiFecth, url, headers);
      const response = yield* call(() => result.json());

      // Fetch Menus
      const urlBis = `${API_ENDPOINT}/menus?shop=${shopId}`;
      const resultMenus = yield* call(apiFecth, urlBis, headers);
      const responseMenus = yield* call(() => resultMenus.json());

      if (Array.isArray(response)) {
        yield put(
          apiRequestSucceeed({
            requestName,
            response: {
              products: response,
              menus: responseMenus,
            },
            url,
          })
        );
      } else {
        yield put(
          apiRequestFailed({
            requestName,
            response,
            errorMessage: response.message,
            url,
          })
        );
      }
    } catch (error) {
      yield put(
        apiRequestFailed({
          // @ts-ignore
          errorMessage: error?.message,
          requestName,
          response: {},
          url,
          payload: {},
        })
      );
    }
  } else if (requestName === 'fetchCart') {
    const cartId = yield* select(selectCartId);
    const { jwt } = yield* select(selectAuth);

    if (!cartId) {
      yield put(
        apiRequestFailed({
          requestName,
          errorMessage: 'No cart yet',
          response: {},
        })
      );
    } else {
      const url = `${API_ENDPOINT}/carts/${cartId}`;
      const headers = {
        Authorization: `Bearer ${jwt}`,
      };

      try {
        const result = yield* call(apiFecth, url, headers);
        const response = yield* call(() => result.json());

        if (typeof response?.id === 'number') {
          // id can be a 0
          yield put(apiRequestSucceeed({ requestName, response, url }));
        } else {
          yield put(
            apiRequestFailed({
              requestName,
              response,
              errorMessage: response.message,
              url,
            })
          );
        }
      } catch (error) {
        yield put(
          apiRequestFailed({
            // @ts-ignore
            errorMessage: error?.message,
            requestName,
            response: {},
            url,
            payload: {},
          })
        );
      }
    }
  } else if (requestName === 'fetchOrders') {
    const { jwt } = yield* select(selectAuth);

    const url = `${API_ENDPOINT}/orders/me`;
    const headers = {
      Authorization: `Bearer ${jwt}`,
    };

    try {
      const result = yield* call(apiFecth, url, headers);
      const response = yield* call(() => result.json());

      if (Array.isArray(response)) {
        yield put(apiRequestSucceeed({ requestName, response, url }));
      } else {
        yield put(
          apiRequestFailed({
            requestName,
            response,
            errorMessage: response.message,
            url,
          })
        );
      }
    } catch (error) {
      yield put(
        apiRequestFailed({
          // @ts-ignore
          errorMessage: error?.message,
          requestName,
          response: {},
          url,
          payload: {},
        })
      );
    }
  } else if (requestName === 'fetchPromotions') {
    const { jwt } = yield* select(selectAuth);

    const url = `${API_ENDPOINT}/ad-tasks`;
    const headers = {
      Authorization: `Bearer ${jwt}`,
    };

    try {
      const result = yield* call(apiFecth, url, headers);
      const response = yield* call(() => result.json());

      console.log('response');
      console.log(response);

      if (Array.isArray(response)) {
        yield put(apiRequestSucceeed({ requestName, response, url }));
      } else {
        yield put(
          apiRequestFailed({
            requestName,
            response,
            errorMessage: response.message,
            url,
          })
        );
      }
    } catch (error) {
      yield put(
        apiRequestFailed({
          // @ts-ignore
          errorMessage: error?.message,
          requestName,
          response: {},
          url,
          payload: {},
        })
      );
    }
  } else if (requestName === 'cartAddProduct') {
    const { id } = yield* select(selectUser);
    const { jwt } = yield* select(selectAuth);

    const url = `${API_ENDPOINT}/products-selections`;
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    };
    const options = {
      userId: id,
      productId: payload.id,
      productQuantity: payload.productQuantity,
      productPrice: payload.productPrice,
    };

    try {
      const result = yield* call(apiPost, url, headers, options);
      const response = yield* call(() => result.json());

      if (response?.cart) {
        yield put(apiRequestSucceeed({ requestName, response, url }));
      } else {
        yield put(
          apiRequestFailed({
            requestName,
            response,
            errorMessage: response.message,
            url,
          })
        );
      }
    } catch (error) {
      // @ts-ignore
      yield put(apiRequestFailed(error?.message));
    }
  } else if (requestName === 'cartAddMenu') {
    const { id } = yield* select(selectUser);
    const { jwt } = yield* select(selectAuth);

    const url = `${API_ENDPOINT}/menu-selections`;
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    };
    const options = {
      userId: id,
      menuId: payload.id,
      price: payload.price,
      selection: payload.selection,
    };

    try {
      const result = yield* call(apiPost, url, headers, options);
      const response = yield* call(() => result.json());

      if (response?.cart) {
        yield put(apiRequestSucceeed({ requestName, response, url }));
      } else {
        yield put(
          apiRequestFailed({
            requestName,
            response,
            errorMessage: response.message,
            url,
          })
        );
      }
    } catch (error) {
      // @ts-ignore
      yield put(apiRequestFailed(error?.message));
    }
  } else if (requestName === 'cartSelectionUpdate') {
    const { jwt } = yield* select(selectAuth);

    const url = `${API_ENDPOINT}/products-selections/${payload.id}`;
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    };
    const options = {
      productQuantity: payload.value,
    };

    try {
      const result = yield* call(apiPut, url, headers, options);
      const response = yield* call(() => result.json());

      if (response?.productsSelection && response?.cart) {
        yield put(apiRequestSucceeed({ requestName, response, url }));
      } else {
        yield put(
          apiRequestFailed({
            requestName,
            response,
            errorMessage: response.message,
            url,
          })
        );
      }
    } catch (error) {
      // @ts-ignore
      yield put(apiRequestFailed(error?.message));
    }
  } else if (requestName === 'cartSelectionRemove') {
    const { jwt } = yield* select(selectAuth);

    const cartItemType = payload.cartItemType as CartItemType;

    const url =
      cartItemType === 'products'
        ? `${API_ENDPOINT}/products-selections/${payload.id}`
        : `${API_ENDPOINT}/menu-selections/${payload.id}`;

    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    };
    try {
      const result = yield* call(apiDelete, url, headers);
      const response = yield* call(() => result.json());

      if (response?.cart) {
        yield put(apiRequestSucceeed({ requestName, response, url }));
      } else {
        yield put(
          apiRequestFailed({
            requestName,
            response,
            errorMessage: response.message,
            url,
          })
        );
      }
    } catch (error) {
      // @ts-ignore
      yield put(apiRequestFailed(error?.message));
    }
  } else if (requestName === 'order') {
    const { id } = yield* select(selectUser);
    const { jwt } = yield* select(selectAuth);

    const url = `${API_ENDPOINT}/orders`;
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    };

    const { cartId, formValues, paymentMethod, stripeInstance } = payload;

    const options = {
      user: id,
      cart: cartId,
      paymentMethod: paymentMethod,
      address: formValues.address,
      city: formValues.city,
      zipcode: formValues.zipcode,
      requestedDate: formValues.requestedDate,
    };

    try {
      // Create the Order (also sends back paymentIntent)
      const result = yield* call(apiPost, url, headers, options);
      const response = yield* call(() => result.json());

      const { order, paymentIntent: paymentIntentFromServer } = response;
      console.log('response');
      console.log(response);

      if (!order?.id || !paymentIntentFromServer?.clientSecret) {
        setIsLoading?.(false);
        alert(response.message);
        yield put(
          apiRequestFailed({
            requestName,
            response,
            errorMessage: response.message,
            url,
          })
        );
        return;
      }

      // Confirm the Card
      const shouldHandleStripeAction =
        (paymentIntentFromServer?.status === 'requires_action' ||
          paymentIntentFromServer?.status === 'requires_confirmation') &&
        !!paymentIntentFromServer?.clientSecret;

      if (shouldHandleStripeAction) {
        const confirmation: any = yield* call(
          stripeInstance.confirmCardPayment,
          response.paymentIntent.clientSecret
        );
        const clientPaymentIntent = confirmation.paymentIntent as PaymentIntent;

        console.log('clientPaymentIntent');
        console.log(clientPaymentIntent);

        if (clientPaymentIntent.status !== 'requires_capture') {
          setIsLoading?.(false);
          alert('An error occured confirming the PaymentIntent');
          apiRequestFailed({
            requestName,
            response,
            errorMessage: 'An error occured confirming the PaymentIntent',
            url,
          });
          return;
        }

        // Update the order
        const updateUrl = `${API_ENDPOINT}/orders/confirm-payment`;
        const options = {
          orderId: order.id,
          paymentIntent: clientPaymentIntent,
        };
        const updateResult = yield* call(apiPost, updateUrl, headers, options);
        const updateResponse = yield* call(() => updateResult.json());

        console.log('updateResponse');
        console.log(updateResponse);

        // Handle error
        if (!updateResponse?.order?.id) {
          setIsLoading?.(false);
          alert(updateResponse.message);
          yield put(
            apiRequestFailed({
              requestName,
              response: updateResponse,
              errorMessage: updateResponse.message,
              url,
            })
          );
          return;
        }
      }

      // Handle success
      yield put(apiRequestSucceeed({ requestName, response, url }));
      window.appHistory.push('/orders');
      yield put(apiRequest('fetchOrders'));
      setIsLoading?.(false);
      alert('Order has been successfully sent');
    } catch (error) {
      setIsLoading?.(false);
      // @ts-ignore
      yield put(apiRequestFailed(error?.message));
    }
  }

  // Stop progress indicator if any
  if (typeof payload.stopRefreshIndicator === 'function') {
    payload.stopRefreshIndicator();
  }
}

const apiSagas = function* apiSagas(): any {
  yield all([takeEvery('API_REQUEST', request)]);
  // yield all([takeEvery('FETCH_SHOP_PRODUCTS', fetchShopMenusAndProducts)]);
};

export default [apiSagas];
