import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import {
  IonContent,
  IonPage,
  IonText,
  IonButton,
  IonIcon,
  isPlatform,
} from '@ionic/react';
import { selectAuth } from '../../../domains/auth/AuthSelectors';
import { IStoreState } from '../../../types/ReduxTypes';
import { apiRequest } from '../../../api/ApiActions';
import LogingForm from '../../components/forms/LoginForm/LoginForm';
import RegisterForm from '../../components/forms/RegisterForm/RegisterForm';
import { logoGoogle } from 'ionicons/icons';
import { IScreenNavigationProps } from '../../../domains/navigation/NavigationTypes';
import Divider from '../../components/Divider/Divider';
import logo from './logo.png';
import styles from './AuthScreen.module.scss';
import { environment } from '../../../config/environments';
import '@teamhive/capacitor-single-signon';
import { Plugins } from '@capacitor/core';

interface IProps extends IScreenNavigationProps {
  login: (values: { email: string; password: string }) => any;
  register: (values: {
    username: string;
    email: string;
    password: string;
  }) => any;
  authentificated: boolean;
  loading: boolean;
  errorMessage?: string;
  //   user: IUser;
  //   cartItemsCount: number;
}

class AuthScreen extends PureComponent<IProps> {
  componentDidMount() {
    if (this.props.authentificated) {
      window.appHistory.replace('/shops');
    }
  }

  singleSignOn = async () => {
    /**
     * See server/extensions/users-permissions/controllers/Auth.js "connect" method
     */
    const authEndpointUrls = {
      web: `${environment.API_ENDPOINT}/connect/google`,
      ios: `${environment.API_ENDPOINT}/connect/google?callback=pocstrapiionic://auth/google/callback`,
    };

    if (isPlatform('desktop')) {
      /**
       * Strapi "The redirect URL to your front-end app" is:
       * http://localhost:8100/auth/google/callback
       */
      window.open(authEndpointUrls.web, '_self');
    } else if (isPlatform('ios')) {
      /**
       * Strapi "The redirect URL to your front-end app" is:
       * pocstrapiionic://auth/google/callback
       */
      const { SingleSignOn } = Plugins;
      try {
        const response = await SingleSignOn.authenticate({
          url: authEndpointUrls.ios,
          customScheme: 'pocstrapiionic://cool',
        });

        const slug = response.url.split('pocstrapiionic://').pop();

        window.appHistory.replace({
          pathname: '/auth/google/callback',
          search: `?${slug}`,
        });
      } catch (error) {
        console.error(error);
      }
    } else if (isPlatform('android')) {
      console.log('Android SSO not yet handled');
    } else {
      console.log('Platform not handled');
    }
  };

  render() {
    const {
      login,
      register,
      loading,
      errorMessage,
      authentificated,
      match: {
        params: { authType },
      },
    } = this.props;

    const showLoader = loading;
    const showErrorMessage = !showLoader && !!errorMessage;

    return (
      <IonPage>
        <IonContent className={styles.content}>
          <div className={styles.logoContainer}>
            <img src={logo} alt="" />
          </div>
          <div className={cx('container', styles.authContainer)}>
            {!authentificated && (
              <>
                <IonText
                  style={{
                    textAlign: 'center',
                    display: 'block',
                    marginLeft: '-0.5em',
                    color: '#00000078',
                    fontSize: '1.4em',
                  }}
                >
                  Early Bird
                </IonText>
                {/* <IonButton
                  type="button"
                  fill="outline"
                  color="dark"
                  onClick={this.singleSignOn}
                  // target="_self"
                  // href={`${environment.API_ENDPOINT}/connect/google`}
                  className={styles.googleSSO}
                >
                  <IonIcon
                    icon={logoGoogle}
                    style={{ marginRight: '10px' }}
                    size="small"
                  />{' '}
                  Sign In
                </IonButton> */}

                {/* <Divider label="Or" /> */}
                <br />
                <br />
                {authType === 'login' && (
                  <>
                    <LogingForm onSubmit={login} showLoader={showLoader} />
                    <br />
                    <IonButton
                      size="small"
                      fill="clear"
                      routerLink="/auth/register"
                      // routerDirection="forward"
                      expand="full"
                      color="dark"
                    >
                      Sign Up
                    </IonButton>
                  </>
                )}
                {authType === 'register' && (
                  <>
                    <RegisterForm onSubmit={register} showLoader={showLoader} />
                    <br />
                    <IonButton
                      size="small"
                      fill="clear"
                      routerLink="/auth/login"
                      // routerDirection="forward"
                      expand="full"
                      color="dark"
                    >
                      Sign In
                    </IonButton>
                  </>
                )}
                {authType === 'forgot-password' && <IonText>TODO</IonText>}
                {authType === 'reset-password' && <IonText>TODO</IonText>}
                {showErrorMessage && (
                  <>
                    <br />
                    <IonText color="danger">{errorMessage}</IonText>
                  </>
                )}
              </>
            )}
            {/* {authentificated && (
              <>
                <IonItem>
                  <IonText color="secondary">
                    <h1>Welcome {user.username}</h1>
                  </IonText>
                </IonItem>
                <IonItem>
                  <IonText color="dark">Cart items: {cartItemsCount}</IonText>
                </IonItem>
                <IonItem>
                  <IonButton
                    size="small"
                    color="dark"
                    routerLink="/menu"
                    routerDirection="forward"
                  >
                    See the menu
                  </IonButton>
                </IonItem>
                <IonItem>
                  <IonButton size="small" color="light" onClick={logout}>
                    Logout
                  </IonButton>
                </IonItem>
              </>
            )} */}
          </div>
        </IonContent>
      </IonPage>
    );
  }
}

const mapStateToProps = (state: IStoreState) => {
  const { authentificated, loading, errorMessage } = selectAuth(state);

  return {
    loading,
    errorMessage,
    authentificated,
    // user,
    // cartItemsCount: cart.items.length,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    login: ({ email, password }: { email: string; password: string }) => {
      dispatch(apiRequest('login', { email, password }));
    },
    register: ({
      username,
      email,
      password,
    }: {
      username: string;
      email: string;
      password: string;
    }) => {
      dispatch(apiRequest('register', { username, email, password }));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthScreen);
