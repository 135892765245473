import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IonList, IonListHeader, IonCardTitle } from '@ionic/react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import {
  IProducts,
  IProduct,
  ProductsByType,
} from '../../../domains/shop/ShopTypes';
import MenuProductsList from './ProductsList';
import { withStyles } from '@mui/styles';

const getDefaultTabIndex = (nextProductsByType: ProductsByType) => {
  if (nextProductsByType.starter.length) {
    return 0;
  }
  if (nextProductsByType.main.length) {
    return 1;
  }
  if (nextProductsByType.dessert.length) {
    return 2;
  }
  return 0;
};

// @ts-ignore
const StyledTabs = withStyles({
  indicator: {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
  },
})(Tabs);

// @ts-ignore
const StyledTab = withStyles({
  root: {
    color: `grey !important`,
    fontFamily: 'inherit',
  },
  selected: {
    // color: `${colors.accent} !important`,
    color: `black !important`,
    fontWeight: 'bold',
    // fontSize: 24,
  },
  disabled: {
    // color: `${colors.accent} !important`,
    color: `rgba(0, 0, 0, 0.2) !important`,
    // fontWeight: 'bold',
    // fontSize: 24,
  },
})(Tab);

interface Props {
  title: string;
  products: IProducts;
  showAddModal: (product: IProduct) => void;
}

const ProductsTabs: React.FC<Props> = (props) => {
  const { title, products, showAddModal } = props;

  const [tabValue, setTabValue] = React.useState(0);

  const [productsByType, setSortedProductsByType] = useState<ProductsByType>({
    starter: [],
    main: [],
    dessert: [],
  });

  // Sort products by types
  useEffect(() => {
    const nextProductsByType: ProductsByType = {
      starter: [],
      main: [],
      dessert: [],
    };

    nextProductsByType.starter = products.filter((p) => p.type === 'starter');
    nextProductsByType.main = products.filter((p) => p.type === 'main');
    nextProductsByType.dessert = products.filter((p) => p.type === 'dessert');

    setSortedProductsByType(nextProductsByType);

    setTabValue(getDefaultTabIndex(nextProductsByType));
  }, [products]);

  const handleChange = useCallback((event: any, value: number) => {
    setTabValue(value);
  }, []);

  return (
    <IonList style={{ backgroundColor: 'transparent', minHeight: '92vh' }}>
      <IonListHeader>
        <IonCardTitle>{title}</IonCardTitle>
      </IonListHeader>

      <Box sx={{ maxWidth: 480, bgcolor: 'background.paper' }}>
        <StyledTabs
          value={tabValue}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          aria-label="scrollable auto tabs example"
          // indicatorColor="red"
        >
          <StyledTab
            label="Starters"
            style={{ textTransform: 'none' }}
            disabled={!productsByType.starter.length}
          />
          <StyledTab
            label="Main courses"
            style={{ textTransform: 'none' }}
            disabled={!productsByType.main.length}
          />
          <StyledTab
            label="Desserts"
            style={{ textTransform: 'none' }}
            disabled={!productsByType.dessert.length}
          />
        </StyledTabs>
      </Box>
      {tabValue === 0 && (
        <MenuProductsList
          products={productsByType.starter}
          showAddModal={showAddModal}
        />
      )}
      {tabValue === 1 && (
        <MenuProductsList
          products={productsByType.main}
          showAddModal={showAddModal}
        />
      )}
      {tabValue === 2 && (
        <MenuProductsList
          products={productsByType.dessert}
          showAddModal={showAddModal}
        />
      )}

      {/* <MenuProductsList
        title="Starters"
        products={productsByType.starter}
        showAddModal={showAddModal}
      />
      <MenuProductsList
        title="Main courses"
        products={productsByType.main}
        showAddModal={showAddModal}
      />
      <MenuProductsList
        title="Desserts"
        products={productsByType.dessert}
        showAddModal={showAddModal}
      /> */}
    </IonList>
  );
};

// const mapStateToProps = (state: IStoreState, ownProps: ShopScreenProps) => {
//   const { products, menus, loading, catalogueErrorMessage } = selectShopState(
//     state
//   );
//   const { amount } = selectCartFull(state);

//   const { shopId } = ownProps.match.params;
//   const shop = selectShop(state, parseInt(shopId)) as IShop;

//   return {
//     products,
//     menus,
//     loading,
//     catalogueErrorMessage,
//     cartAmount: amount,
//     shop,
//   };
// };

// const mapDispatchToProps = (dispatch: any, ownProps: ShopScreenProps) => {
//   return {
//     fetchShopMenusAndProducts: (
//       shopId: number,
//       stopRefreshIndicator?: Function
//     ) => {
//       dispatch(
//         apiRequest('fetchShopMenusAndProducts', {
//           shopId,
//           stopRefreshIndicator,
//         })
//       );
//     },
//     fetchCart: (stopRefreshIndicator?: Function) => {
//       dispatch(apiRequest('fetchCart', { stopRefreshIndicator }));
//     },
//     addProductToCart: (
//       id: number,
//       productQuantity: number,
//       productPrice: number
//     ) => {
//       dispatch(
//         apiRequest('cartAddProduct', { id, productQuantity, productPrice })
//       );
//     },
//   };
// };

export default connect()(ProductsTabs);
