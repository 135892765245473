import React from 'react';
import { IonText, IonList, IonRow, IonGrid, IonCol } from '@ionic/react';
import { IProducts, IProduct } from '../../../domains/shop/ShopTypes';
import ProductItem from '../../components/ProductItem/ProductItem';

interface ShopScreenProps {
  title?: string;
  products: IProducts;
  showAddModal?: (product: IProduct) => any;
}

const MenuProductsList: React.FC<ShopScreenProps> = (props) => {
  const { title, products, showAddModal } = props;

  return (
    <IonList style={{ backgroundColor: 'transparent' }}>
      <div
        style={{
          marginTop: '10px',
          paddingInlineStart: 'calc(var(--ion-safe-area-left, 0px) + 20px)',
        }}
      >
        {!!title && (
          <IonText>
            <h5>
              <span role="img" aria-label="">
                🍴
              </span>{' '}
              {title}
            </h5>
          </IonText>
        )}
      </div>
      <IonGrid className="ion-no-padding">
        <IonRow>
          {products?.map((product) => {
            return (
              <IonCol
                key={product.id}
                className="ion-no-padding"
                sizeXl="3"
                sizeLg="3"
                sizeMd="6"
                sizeSm="6"
                sizeXs="12"
              >
                <ProductItem
                  key={product.id}
                  product={product}
                  showAddModal={showAddModal}
                />
              </IonCol>
            );
          })}
        </IonRow>
      </IonGrid>
    </IonList>
  );
};

export default MenuProductsList;
