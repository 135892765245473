import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
// import { StripeProvider, Elements } from 'react-stripe-elements';
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
} from '@ionic/react';
import { IStoreState } from '../../../types/ReduxTypes';
import { apiRequest } from '../../../api/ApiActions';
import { selectCartFull } from '../../../domains/shop/ShopSelectors';
import { ICartFull } from '../../../domains/shop/ShopTypes';
import CheckoutForm, { OrderFormValuesType } from './CheckoutForm';
import { environment } from '../../../config/environments';
import { loadStripe, PaymentMethod } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

interface IProps {
  // order: (
  //   cartId: number,
  //   formValues: FormValuesType & { token: string }
  // ) => any;
  order: (
    cartId: number,
    formValues: OrderFormValuesType,
    paymentMethod: PaymentMethod,
    stripeInstance: any,
    setIsLoading: Function
  ) => any;
  cartFull: ICartFull;
}

interface Istate {
  // formValues: FormValuesType;
}

const screenTitle = 'Checkout';

const stripePromise = loadStripe(environment.STRIPE_API_KEY);

console.log('???', environment.STRIPE_API_KEY);

class CheckoutScreen extends PureComponent<IProps, any> {
  state = {
    // items: {},
    // stripe: null,
  };

  // componentDidMount() {
  //   if (window.Stripe) {
  //     this.setState({ stripe: window.Stripe(environment.STRIPE_API_KEY) });
  //   } else {
  //     document.querySelector('#stripe-js')?.addEventListener('load', () => {
  //       this.setState({ stripe: window.Stripe(environment.STRIPE_API_KEY) });
  //     });
  //   }
  // }

  // submitOrder = (payload: FormValuesType & { token: string }) => {
  submitOrder = ({
    formValues,
    paymentMethod,
    stripeInstance,
    setIsLoading,
  }: {
    formValues: OrderFormValuesType;
    paymentMethod: PaymentMethod;
    stripeInstance: any;
    setIsLoading: Function;
  }) => {
    this.props.order(
      this.props.cartFull.id,
      formValues,
      paymentMethod,
      stripeInstance,
      setIsLoading
    );
  };

  render() {
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton defaultHref="cart" />
            </IonButtons>
            <IonTitle>{screenTitle}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonHeader collapse="condense">
            <IonToolbar>
              <IonTitle size="large">{screenTitle}</IonTitle>
            </IonToolbar>
          </IonHeader>
          <div className="container">
            <Elements stripe={stripePromise}>
              <CheckoutForm
                submitOrder={this.submitOrder}
                amount={this.props.cartFull.amount}
              />
            </Elements>
          </div>
        </IonContent>
      </IonPage>
    );
  }
}

const mapStateToProps = (state: IStoreState) => {
  const cartFull = selectCartFull(state);

  return {
    cartFull,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    order: (
      cartId: number,
      formValues: OrderFormValuesType,
      paymentMethod: PaymentMethod,
      stripeInstance: any,
      setIsLoading: Function
    ) => {
      dispatch(
        apiRequest('order', {
          cartId,
          formValues,
          paymentMethod,
          stripeInstance,
          setIsLoading,
        })
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutScreen);
