import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Dispatch } from 'redux';
import { useHistory } from 'react-router-dom';
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { megaphone, people, storefront, list } from 'ionicons/icons';
import ProtectedRoute from './view/ProtectedRoute';
import AuthScreen from './view/pages/AuthScreen/AuthScreen';
import AuthLoadingScreen from './view/pages/AuthLoadingScreen';
import ShopScreen from './view/pages/ShopScreen/ShopScreen';
import PromotionsScreen from './view/pages/PromotionsScreen/PromotionsScreen';
import CartScreen from './view/pages/CartScreen';
import CheckoutScreen from './view/pages/Checkout/CheckoutScreen';
import OrdersScreen from './view/pages/OrdersScreen';
import ProfileScreen from './view/pages/ProfileScreen/ProfileScreen';
import { setInitialApparenceMode } from './domains/theme/theme';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './view/theme/variables.css';

// Slick
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// Custom
import './view/theme/custom.css';
import { appStart } from './domains/lifecycle/LifeCycleActions';
import ShopsScreen from './view/pages/ShopsScreen/ShopsScreen';
import MenuScreen from './view/pages/MenuScreen/MenuScreen';

setInitialApparenceMode();

/**
 * Router part
 */

const SwitchRoutes: React.FC = () => {
  /**
   * Store the history object globally so we can access it outside of React components
   * See issue: https://github.com/ionic-team/ionic/issues/20297#issuecomment-625445957
   */
  window.appHistory = useHistory();

  return (
    <Switch>
      <Route path="/auth/:authType?" component={AuthScreen} exact={true} />
      <Route
        path="/auth/:provider/callback"
        component={AuthLoadingScreen}
        exact={true}
      />
      {/* Modal routes (above the tabs sscene */}
      <ProtectedRoute path="/checkout" component={CheckoutScreen} />
      <IonTabs>
        <IonRouterOutlet>
          <ProtectedRoute path="/shops" component={ShopsScreen} exact={true} />
          <ProtectedRoute
            path="/shops/:shopId"
            component={ShopScreen}
            exact={true}
          />
          <ProtectedRoute
            path="/shops/:shopId/menu/:menuId"
            component={MenuScreen}
            exact={true}
          />
          <ProtectedRoute
            path="/promotions"
            component={PromotionsScreen}
            exact={true}
          />
          <ProtectedRoute path="/cart" component={CartScreen} />
          <ProtectedRoute path="/orders" component={OrdersScreen} />
          <ProtectedRoute path="/profile" component={ProfileScreen} />
          <Route
            path="/"
            render={() => <Redirect to="/auth/login" />}
            exact={true}
          />
        </IonRouterOutlet>
        <IonTabBar slot="bottom">
          <IonTabButton tab="shops" href="/shops">
            <IonIcon icon={storefront} />
            <IonLabel>Explore</IonLabel>
          </IonTabButton>
          <IonTabButton tab="orders" href="/orders">
            <IonIcon icon={list} />
            <IonLabel>Orders</IonLabel>
          </IonTabButton>
          <IonTabButton tab="promotions" href="/promotions">
            <IonIcon icon={megaphone} />
            <IonLabel>Promotions</IonLabel>
          </IonTabButton>
          <IonTabButton tab="profile" href="/profile">
            <IonIcon icon={people} />
            <IonLabel>Profile</IonLabel>
          </IonTabButton>
        </IonTabBar>
      </IonTabs>
    </Switch>
  );
};

class App extends Component<{ dispatch: Dispatch }> {
  componentDidMount() {
    this.props.dispatch(appStart());
  }

  render() {
    return (
      <IonApp>
        <IonReactRouter>
          <SwitchRoutes />
        </IonReactRouter>
      </IonApp>
    );
  }
}

export default App;
