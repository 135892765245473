import { createSelector } from 'reselect';
import { IStoreState } from '../../types/ReduxTypes';
import { IShopState } from './ShopTypes';

const defaultCart = {}; // Default cart to avoid repetitive null management in components when the user doesn't have cart.

const getShop: (state: IStoreState) => IShopState = (state) => state.shop;

export const selectShopState = createSelector(
  getShop,
  (shopState) => shopState
);

export const selectProducts = createSelector(
  getShop,
  (shopState) => shopState.products
);

export const selectCartFull = createSelector(
  selectShopState,
  (shopState) => shopState.cartFull || defaultCart
);

export const selectCartId = createSelector(
  selectShopState,
  (shopState) => shopState.cartId
);

export const selectOrders = createSelector(
  selectShopState,
  (shopState) => shopState.orders
);

export const selectShops = createSelector(
  selectShopState,
  (shopState) => shopState.shops
);

// This selector will return Article data base on articleId as dynamic arg.
export const selectShop = createSelector(
  [
    selectShops, // get the articles map data from the state.
    (state: IStoreState, shopId: number) => shopId, // get the dynamic arg.
    // you can add more select functions state/dynamic arg.
  ],
  (shops, shopId) => shops.find((shop) => shop.id === shopId)
);

// export const selectShop = createSelector(selectShops, (shops) =>
//   memoize((shopId: number) => {
//     console.log('shops');
//     console.log(shops);
//     return shops[shopId];
//   })
// );
