import { isDev } from '../../utils/utils';
import { CartItemType, IShopState } from './ShopTypes';

export const INITIAL_STATE: IShopState = {
  shops: [],
  isShopsLoading: false,
  shopsErrorMessage: '',
  products: [],
  menus: [],
  loading: false,
  catalogueErrorMessage: '',
  cartErrorMessage: '',
  cartId: 0,
  cartFull: {
    id: 0,
    user: 0,
    amount: 0,
    created_at: '',
    updated_at: '',
    productsSelections: [],
    menusSelections: [],
  },
  isCartIsLoading: false,
  orders: [],
  isOrdersLoading: false,
  orderErrorMessage: '',
  promotions: [],
  isPromotionsLoading: false,
  promotionsErrorMessage: '',
};

export default (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case 'LOGIN_SUCCEEDED': {
      return {
        ...state,
        cartId: action.user.cart?.id,
        cartFull: action.user.cart,
      };
    }
    case 'LOGOUT': {
      return { ...INITIAL_STATE };
    }
    case 'API_REQUEST': {
      if (action.requestName === 'fetchShops') {
        return {
          ...state,
          isShopsLoading: true,
          shopsErrorMessage: '',
        };
      }
      if (action.requestName === 'fetchShopMenusAndProducts') {
        return {
          ...state,
          loading: true,
          catalogueErrorMessage: '',
        };
      }
      if (action.requestName === 'fetchCart') {
        return {
          ...state,
          isCartIsLoading: true,
          cartErrorMessage: '',
        };
      }
      if (action.requestName === 'fetchOrders') {
        return {
          ...state,
          isOrdersLoading: true,
          orderErrorMessage: '',
        };
      }
      if (action.requestName === 'fetchPromotions') {
        return {
          ...state,
          isPromotionsLoading: true,
          promotionsErrorMessage: '',
        };
      }
      if (action.requestName === 'cartAddProduct') {
        return {
          ...state,
          isCartIsLoading: true,
        };
      }
      if (action.requestName === 'cartAddMenu') {
        return {
          ...state,
          isCartIsLoading: true,
        };
      }
      if (action.requestName === 'cartSelectionUpdate') {
        const cartItemType = action.payload.cartItemType as CartItemType;
        if (cartItemType === 'products') {
          // Update the state temporary, waiting for the request response, for a better UX
          const newProductsSelections = state.cartFull.productsSelections.map(
            (selection) => {
              if (selection.id === action.payload.id) {
                return {
                  ...selection,
                  productQuantity: action.payload.value,
                  amount: action.payload.value * selection.productPrice,
                };
              }
              return selection;
            }
          );

          return {
            ...state,
            isCartIsLoading: true,
            cartFull: {
              ...state.cartFull,
              productsSelections: newProductsSelections,
            },
          };
        } else if (cartItemType === 'menu') {
          if (isDev) {
            console.log('cartSelectionUpdate is only supported for products');
          }
          return state;
        }
      }
      if (action.requestName === 'cartSelectionRemove') {
        return {
          ...state,
          isCartIsLoading: true,
        };
      }
      return state;
    }
    case 'API_REQUEST_SUCCEEDED': {
      if (action.requestName === 'fetchShops') {
        return {
          ...state,
          isShopsLoading: false,
          shopsErrorMessage: '',
          shops: action.response,
        };
      }
      if (action.requestName === 'fetchShopMenusAndProducts') {
        return {
          ...state,
          products: action.response.products,
          menus: action.response.menus,
          loading: false,
          catalogueErrorMessage: '',
        };
      }
      if (action.requestName === 'fetchCart') {
        return {
          ...state,
          isCartIsLoading: false,
          cartErrorMessage: '',
          cartId: action.response.id,
          cartFull: action.response,
        };
      }
      if (action.requestName === 'fetchOrders') {
        return {
          ...state,
          isOrdersLoading: false,
          orderErrorMessage: '',
          orders: action.response,
        };
      }
      if (action.requestName === 'fetchPromotions') {
        return {
          ...state,
          isPromotionsLoading: false,
          promotionsErrorMessage: '',
          promotions: action.response,
        };
      }
      if (action.requestName === 'cartAddProduct') {
        return {
          ...state,
          isCartIsLoading: false,
          cartId: action.response.cart.id,
          cartFull: action.response.cart,
        };
      }
      if (action.requestName === 'cartAddMenu') {
        return {
          ...state,
          isCartIsLoading: false,
          cartId: action.response.cart.id,
          cartFull: action.response.cart,
        };
      }
      if (action.requestName === 'cartSelectionUpdate') {
        return {
          ...state,
          isCartIsLoading: false,
          cartId: action.response.cart.id,
          cartFull: action.response.cart,
        };
      }
      if (action.requestName === 'cartSelectionRemove') {
        return {
          ...state,
          isCartIsLoading: false,
          cartId: action.response.cart.id,
          cartFull: action.response.cart,
        };
      }
      return state;
    }
    case 'API_REQUEST_FAILED': {
      if (action.requestName === 'fetchShops') {
        return {
          ...state,
          isShopsLoading: false,
          shopsErrorMessage: action.errorMessage,
        };
      }
      if (action.requestName === 'fetchShopMenusAndProducts') {
        return {
          ...state,
          loading: false,
          catalogueErrorMessage: action.errorMessage || '',
        };
      }
      if (action.requestName === 'fetchCart') {
        return {
          ...state,
          isCartIsLoading: false,
          cartErrorMessage: action.errorMessage,
        };
      }
      if (action.requestName === 'fetchOrders') {
        return {
          ...state,
          isOrdersLoading: false,
          orderErrorMessage: action.errorMessage,
        };
      }
      if (action.requestName === 'fetchPromotions') {
        return {
          ...state,
          isPromotionsLoading: false,
          promotionsErrorMessage: action.errorMessage,
        };
      }
      if (action.requestName === 'cartAddProduct') {
        return {
          ...state,
          isCartIsLoading: false,
          cartErrorMessage: action.errorMessage,
        };
      }
      if (action.requestName === 'cartSelectionUpdate') {
        return {
          ...state,
          isCartIsLoading: false,
          cartErrorMessage: action.errorMessage,
        };
      }
      if (action.requestName === 'cartSelectionRemove') {
        return {
          ...state,
          isCartIsLoading: false,
          cartErrorMessage: action.errorMessage,
        };
      }
      return state;
    }
    default:
      return state;
  }
};
