import React, { useState, useEffect, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';
import {
  IonButton,
  IonButtons,
  IonCheckbox,
  IonContent,
  IonHeader,
  IonIcon,
  IonModal,
  IonText,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import styles from './MenuSelectorStepper.module.scss';
import {
  IProducts,
  MenuSelection,
  ProductTypeType,
} from '../../../domains/shop/ShopTypes';
import ProductItem from '../../components/ProductItem/ProductItem';
import { basket } from 'ionicons/icons';
import { red } from '@mui/material/colors';

// const steps = [
//   {
//     label: 'Select campaign settings',
//     description: `For each ad campaign that you create, you can control how much
//               you're willing to spend on clicks and conversions, which networks
//               and geographical locations you want your ads to show on, and more.`,
//   },
//   {
//     label: 'Create an ad group',
//     description:
//       'An ad group contains one or more ads which target a shared set of keywords.',
//   },
//   {
//     label: 'Create an ad',
//     description: `Try out different ad text to see what brings in the most customers,
//               and learn how to enhance your ads using features like ad extensions.
//               If you run into any problems with your ads, find out how to tell if
//               they're running and how to resolve approval issues.`,
//   },
// ];

type StepsType = Array<{
  type: ProductTypeType;
  title: string;
  subtitle: string;
  items: IProducts;
  optional: boolean;
}>;

const getSteps = (props: Props) => {
  const {
    starters,
    mainCourses,
    desserts,
    starterMandatory,
    mainCourseMandatory,
    dessertMandatory,
  } = props;

  const steps: StepsType = [];

  if (starters.length) {
    steps.push({
      type: 'starter',
      title: 'Starters',
      subtitle: 'Please choose a starter',
      items: starters,
      optional: !starterMandatory,
    });
  }

  if (mainCourses.length) {
    steps.push({
      type: 'main',
      title: 'Main Courses',
      subtitle: 'Please choose a main course',
      items: mainCourses,
      optional: !mainCourseMandatory,
    });
  }

  if (desserts.length) {
    steps.push({
      type: 'dessert',
      title: 'Desserts',
      subtitle: 'Please choose a dessert',
      items: desserts,
      optional: !dessertMandatory,
    });
  }

  return steps;
};

interface Props {
  isOpen: boolean;
  hide: (e: any) => any;
  onValidate: (selection: MenuSelection, handleReset: Function) => any;
  menuName: string;
  price: number;
  starters: IProducts;
  mainCourses: IProducts;
  desserts: IProducts;
  starterMandatory: boolean;
  mainCourseMandatory: boolean;
  dessertMandatory: boolean;
}

const VerticalLinearStepper: React.FC<Props> = (props) => {
  const {
    isOpen,
    hide,
    onValidate,
    menuName,
    price,
    starters,
    mainCourses,
    desserts,
    starterMandatory,
    mainCourseMandatory,
    dessertMandatory,
  } = props;

  const [activeStep, setActiveStep] = useState(0);
  const [steps, setSteps] = useState(getSteps(props));
  const [selection, setSelections] = useState<MenuSelection>({
    starter: undefined,
    main: undefined,
    dessert: undefined,
  });

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleAddToCart = useCallback(() => {
    onValidate(selection, handleReset);
  }, [selection, onValidate, handleReset]);

  const onSelect = useCallback(
    (type: string, id: number) => {
      setSelections({
        ...selection,
        [type]: id,
      });
      handleNext();
    },
    [selection]
  );

  useEffect(() => {
    setSteps(getSteps(props));
  }, [
    starters,
    mainCourses,
    desserts,
    starterMandatory,
    mainCourseMandatory,
    dessertMandatory,
  ]);

  return (
    <IonModal
      isOpen={isOpen}
      swipeToClose
      cssClass={styles.modal}
      onDidDismiss={hide}
    >
      <IonHeader translucent>
        <IonToolbar>
          <IonTitle>{`${menuName} - ${price}€`}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={hide}>Cancel</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen className={styles.modalContent}>
        <IonContent className="ion-padding">
          <Box sx={{ maxWidth: 400 }}>
            <Stepper
              activeStep={activeStep}
              orientation="vertical"
              className={styles.stepper}
            >
              {steps.map((step, index) => {
                const onlyOneItem = step.items.length === 1;
                return (
                  <Step key={step.type}>
                    <StepLabel
                      classes={{
                        active: styles.stepperLabelActive,
                        // iconContainer: styles.stepperIconContainer,
                        // iconContainer: styles.stepperLabelActive,
                      }}
                      StepIconProps={{
                        classes: {
                          root:
                            index <= activeStep
                              ? styles.stepperIconActive
                              : styles.stepperIconGreyedOut,
                        },
                      }}
                      // optional={
                      //   index === 1 ? (
                      //     <Typography variant="caption">Last step</Typography>
                      //   ) : null
                      // }
                    >
                      {step.optional ? `${step.title} (Optional)` : step.title}
                    </StepLabel>
                    <StepContent>
                      <Typography>{step.subtitle}</Typography>
                      {step.items.map((product) => {
                        return (
                          <div
                            key={product.id}
                            onClick={() => onSelect(step.type, product.id)}
                          >
                            <ProductItem
                              product={product}
                              showAddModal={() => undefined}
                              style={{ marginLeft: 0 }}
                            />
                          </div>
                        );
                      })}
                      <Box sx={{ mb: 2 }}>
                        <div>
                          {step.optional && (
                            <Button
                              variant="contained"
                              onClick={handleNext}
                              sx={{ mt: 1, mr: 1 }}
                            >
                              {index === steps.length - 1 ? 'Finish' : 'Ignore'}
                            </Button>
                          )}
                          {index !== 0 && (
                            <IonButton
                              onClick={handleBack}
                              color="light"
                              size="small"
                            >
                              <IonText>Back</IonText>
                            </IonButton>
                          )}
                        </div>
                      </Box>
                    </StepContent>
                  </Step>
                );
              })}
            </Stepper>
            {activeStep === steps.length && (
              <Paper square elevation={0} sx={{ p: 3 }}>
                <Typography>
                  All steps completed - you&apos;re finished!
                </Typography>
                <br />
                {/* <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                  Reset
                </Button> */}
                <IonButton onClick={handleAddToCart} color="dark">
                  <IonIcon
                    icon={basket}
                    style={{ marginRight: '10px' }}
                    size="small"
                  />{' '}
                  <IonText>
                    <p>Validate</p>
                  </IonText>
                </IonButton>
                <IonButton onClick={handleBack} fill="clear" color="dark">
                  <IonText>Back</IonText>
                </IonButton>
              </Paper>
            )}
          </Box>
        </IonContent>
      </IonContent>
    </IonModal>
  );
};

export default VerticalLinearStepper;
