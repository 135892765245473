import React, { useCallback, useState } from 'react';
import {
  IonText,
  IonCard,
  IonCardTitle,
  IonRow,
  IonButton,
  IonLabel,
  IonAlert,
  IonCol,
} from '@ionic/react';
import {
  CartItemType,
  ICartProductSelection,
} from '../../../domains/shop/ShopTypes';
import styles from './CartItem.module.scss';

interface IProps {
  cartSelection: ICartProductSelection;
  updateSelection: (o: {
    cartItemType: CartItemType;
    id: number;
    value: number;
  }) => any;
  removeSelection: (cartItemType: CartItemType, id: number) => any;
  style?: any;
}

const QuantityAndAmount: React.FC<IProps> = (props) => {
  const {
    cartSelection: { id, amount, productQuantity },
    updateSelection,
    removeSelection,
  } = props;

  const [showRemoveAlert, setShowRemoveAlert] = useState(false);

  const decrement = useCallback(() => {
    if (productQuantity !== 1) {
      updateSelection({
        cartItemType: 'products',
        id,
        value: productQuantity - 1,
      });
    } else {
      setShowRemoveAlert(true);
    }
  }, [id, productQuantity, updateSelection]);

  const increment = useCallback(() => {
    updateSelection({
      cartItemType: 'products',
      id,
      value: productQuantity + 1,
    });
  }, [id, productQuantity, updateSelection]);

  const remove = useCallback(() => {
    removeSelection('products', id);
  }, [id, removeSelection]);

  return (
    <IonRow class="ion-align-items-center ion-justify-content-between">
      <IonRow class="ion-align-items-center">
        <IonButton color="light" onClick={decrement}>
          <IonText>
            <p>-</p>
          </IonText>
        </IonButton>

        <IonLabel className="ion-padding">
          <IonText>
            <h2>{productQuantity}</h2>
          </IonText>
        </IonLabel>

        <IonButton color="light" onClick={increment}>
          <IonText>
            <p>+</p>
          </IonText>
        </IonButton>
      </IonRow>

      <IonText>
        <h2>{amount}$</h2>
      </IonText>

      <IonAlert
        isOpen={showRemoveAlert}
        onDidDismiss={() => setShowRemoveAlert(false)}
        cssClass="my-custom-class"
        header={'Caution'}
        message={'Are you sure to remove this item?'}
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => setShowRemoveAlert(false),
          },
          {
            text: 'Remove',
            cssClass: 'secondary',
            handler: remove,
          },
        ]}
      />
    </IonRow>
  );
};

const CartItem: React.FC<IProps> = (props: IProps) => {
  const {
    cartSelection: { id, product },
  } = props;

  return (
    <IonCard key={id}>
      <div className={styles.cardHeader}>
        <div
          style={{
            // backgroundImage: `url(${environment.API_ENDPOINT}${product.picture?.url})`,
            backgroundImage: `url(${product.picture?.url})`,
          }}
          className={styles.imgBg}
        />
        {/* <img src={`${environment.API_ENDPOINT}${product.picture?.url}`} alt="" /> */}
        <IonCol className="ion-padding">
          <IonCardTitle className={styles.cardTitle}>
            {product.name}
          </IonCardTitle>
          <br />
          <QuantityAndAmount {...props} />
        </IonCol>
      </div>
    </IonCard>
  );
};

export default CartItem;
