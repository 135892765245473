import React from 'react';
import {
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCardContent,
  IonText,
  IonRouterLink,
  IonRow,
  IonCol,
  IonGrid,
} from '@ionic/react';
import { IShop } from '../../../domains/shop/ShopTypes';
import styles from './ShopItem.module.scss';

interface IProps {
  shop: IShop;
}

const ShopItem: React.FC<IProps> = (props: IProps) => {
  const {
    shop: { id, name, city, picture, logo, tagline, taglineBis },
  } = props;

  return (
    <IonRouterLink routerLink={`/shops/${id}`}>
      <IonCard className={styles.root}>
        <div
          className={styles.picture}
          style={{
            backgroundImage: `url(${picture?.url})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'top center',

            height: '10vh',
            minHeight: '200px',
          }}
        />
        {/* 
        <IonCardHeader>
          <IonCardSubtitle>#{id}</IonCardSubtitle>
          <IonCardTitle>{name}</IonCardTitle>
          <IonCardSubtitle>{price}$</IonCardSubtitle>
        </IonCardHeader>
           */}

        <IonCardContent
          style={{
            // position: 'absolute',
            // top: 100,
            // bottom: 0,
            // left: 0,
            // rigth: 0,
            // width: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.85)',
            padding: 6,
          }}
        >
          <IonGrid>
            <IonRow
              style={{
                flexWrap: 'nowrap',
              }}
            >
              <IonCol style={{ flex: 0, marginRight: 10 }}>
                <img
                  src={logo?.url}
                  alt=""
                  style={{
                    minWidth: 50,
                    height: 50,
                    borderRadius: 100,
                  }}
                />
              </IonCol>
              <IonCol
                style={{
                  marginTop: -2,
                  flex: 1,
                  flexShrink: 1,
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              >
                {/* <IonCardSubtitle>{city}</IonCardSubtitle> */}
                <IonCardTitle>{name}</IonCardTitle>
                {/* <IonText>{tagline}</IonText>
          <br /> */}
                <IonText style={{}}>{tagline}</IonText>
                {/* <IonText>{city}</IonText> */}
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonCardContent>

        {/* <IonButton
        fill="outline"
        color="dark"
        onClick={onAdd}
        className="ion-float-right ion-margin"
      >
        <IonIcon icon={add} color="dark" />
      </IonButton> */}
      </IonCard>
    </IonRouterLink>
  );
};

export default ShopItem;
