import { IPushNotificationsState } from './PushNotificationsTypes';
import { REHYDRATE } from 'redux-persist';

export const INITIAL_STATE: IPushNotificationsState = {
  pushUserId: '',
  pushToken: '',
};

export default (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case REHYDRATE: {
      const { pushNotifications = {} } = action?.payload || {};
      return {
        ...INITIAL_STATE,
        ...pushNotifications,
      };
    }
    case 'PUSH_NOTIFICATIONS_REGISTRATION': {
      return {
        ...state,
        pushUserId: action.pushUserId,
        pushToken: action.pushToken,
      };
    }
    default:
      return state;
  }
};
