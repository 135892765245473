import { isCapacitor, isIOS, isAndroid } from '../../utils/platform';
import { setFlagsFromString } from 'v8';

const isRealDevice = true;
const myIP = '192.168.1.70'; // TODO from .env, it should depends from the dev member

let devDomain = 'localhost';

if (isCapacitor) {
  // prevents flase positive when debuggin on Chrome in mobile mode
  if (isIOS && isRealDevice) {
    devDomain = myIP;
  } else if (isAndroid) {
    devDomain = isRealDevice ? myIP : '10.0.2.2';
  }
}

export default {
  production: false,
  API_ENDPOINT: `http://${devDomain}:1337`,
  // API_ENDPOINT: `https://pocstrapiionic.herokuapp.com`,
  ONESIGNAL_APP_ID: '246c15aa-552a-46a2-ad4e-99802b51d1d0',
  ONESIGNAL_GOOGLE_SENDER_ID: '344964455026',
  STRIPE_API_KEY:
    'pk_test_51JQKJuGc5f5SjlzfbT7uuyl9On70FQ0i4qUDr1xN2PmN3zTl0LTMADUFe2CJUXGF1qOr8LM2UxSgkHbIy6RbFWox00ibX7RfoG',
};
