import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonText,
  IonSpinner,
  IonList,
  IonButtons,
  IonButton,
  IonRefresher,
  IonRefresherContent,
  IonListHeader,
  IonModal,
  IonRow,
  IonGrid,
  IonCol,
  IonBackButton,
  IonCardTitle,
  IonCardSubtitle,
} from '@ionic/react';
import Slider from 'react-slick';
import {
  IProducts,
  IProduct,
  IShop,
  IMenus,
} from '../../../domains/shop/ShopTypes';
import {
  selectShopState,
  selectShop,
  selectCartFull,
} from '../../../domains/shop/ShopSelectors';
import { IStoreState } from '../../../types/ReduxTypes';
import { apiRequest } from '../../../api/ApiActions';
import MenuItem from '../../components/MenuItem/MenuItem';
import styles from './ShopScreen.module.scss';
import { RouteComponentProps } from 'react-router';
import HeaderCartButton from '../../components/HeaderCartButton/HeaderCartButton';
import ProductsTabs from './ProductsTabs';
interface IState {
  isAddModalVisible: boolean;
  modalProduct: {} | IProduct;
  modalProductQuantity: number;
}

interface ShopScreenProps
  extends RouteComponentProps<{
    shopId: string;
  }> {
  products: IProducts;
  menus: IMenus;
  fetchShopMenusAndProducts: (
    id: number,
    stopRefreshIndicator?: Function
  ) => any;
  fetchCart: (stopRefreshIndicator?: Function) => any;
  addProductToCart: (productId: number, quantity: number, price: number) => any;
  loading: boolean;
  catalogueErrorMessage?: string;
  cartAmount?: number;
  shop: IShop;
}

const longText =
  "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.";

const slickSettings = {
  dots: false,
  speed: 500,

  slidesToShow: 4.2,
  slidesToScroll: 4,
  infinite: false, // trick to show 4.x slides
  centerMode: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4.2,
        slidesToScroll: 4,
        infinite: false, // trick to show 4.x slides
      },
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 3.2,
        slidesToScroll: 3,
        infinite: false, // trick to show 3.x slides
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2.2,
        slidesToScroll: 2,
        infinite: false, // trick to show 2.x slides
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1.2,
        slidesToScroll: 1,
        infinite: false, // trick to show 1.x slides
      },
    },
  ],
};

const ShopScreen: React.FC<ShopScreenProps> = (props) => {
  const {
    products,
    menus,
    loading,
    catalogueErrorMessage,
    // cartAmount,
    shop,
    fetchShopMenusAndProducts,
    fetchCart,
    addProductToCart,
    match,
  } = props;

  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [modalProduct, setModalProduct] = useState({});
  const [modalProductQuantity, setModalProductQuantity] = useState(0);

  const resresh = useCallback(
    (e: any) => {
      const id = parseInt(match.params.shopId);
      fetchShopMenusAndProducts(id, e.detail.complete);
      fetchCart(e.detail.complete);
    },
    [fetchCart, fetchShopMenusAndProducts, match.params.shopId]
  );

  const showAddModal = useCallback((product: IProduct) => {
    setIsAddModalVisible(true);
    setModalProduct(product);
    setModalProductQuantity(1);
  }, []);

  const hideAddModal = useCallback(() => {
    setIsAddModalVisible(false);
    setModalProduct({});
    setModalProductQuantity(0);
  }, []);

  const modalProductQuantityChanged = useCallback((value: number) => {
    setModalProductQuantity(value);
  }, []);

  const validateAddModal = useCallback(
    (e: any) => {
      const castedProduct = modalProduct as IProduct;
      addProductToCart(
        castedProduct?.id,
        modalProductQuantity,
        castedProduct?.price
      );
      hideAddModal();
    },
    [addProductToCart, hideAddModal, modalProduct, modalProductQuantity]
  );

  // did mount
  useEffect(() => {
    const id = parseInt(match.params.shopId);
    fetchShopMenusAndProducts(id);
    fetchCart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showLoader = loading;
  const showErrorMessage = !showLoader && !!catalogueErrorMessage;

  const showContent = !showLoader && !showErrorMessage;

  // TODO Loic: make modalProduct aquals to "null" or "IProduct", and use it in the view
  const castedProduct = modalProduct as IProduct;

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/shops" />
          </IonButtons>
          <IonTitle>{shop.name}</IonTitle>

          <IonButtons slot="end">
            <HeaderCartButton />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{shop.name}</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonRefresher slot="fixed" onIonRefresh={resresh}>
          <IonRefresherContent />
        </IonRefresher>

        <div className="ion-padding">
          <IonGrid>
            <IonRow>
              <IonCol style={{ flex: 0 }}>
                <div
                  style={{
                    backgroundImage: `url(${shop.logo?.url})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'top center',

                    borderRadius: 6,
                    width: 100,
                    height: 100,
                  }}
                />
              </IonCol>
              <IonCol style={{ flex: 1, paddingTop: 16 }}>
                <IonCardSubtitle>{shop.tagline}</IonCardSubtitle>
                <IonCardTitle style={{ fontSize: 20 }}>
                  {shop.taglineBis}
                </IonCardTitle>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>

        {!showContent && (
          <div className="container">
            {showLoader && <IonSpinner />}
            {showErrorMessage && (
              <IonText color="danger">{catalogueErrorMessage}</IonText>
            )}
          </div>
        )}
        {showContent && (
          <IonList style={{ backgroundColor: 'transparent' }}>
            <IonListHeader>
              <IonCardTitle>Menus</IonCardTitle>
            </IonListHeader>

            {/* <IonGrid> */}
            {/* <IonRow> */}
            <div>
              <Slider
                {...slickSettings}
                // style={{
                //   maxWidth: '100vw',
                //   width: '100%',
                // }}
                className={styles.sliderMenus}
                // centerMode={true}
                // slidesPerView={3}
                // onSlideChange={() => console.log('slide change')}
              >
                {menus?.map((menu) => {
                  // {
                  /* <IonCol
                        key={product.id}
                        sizeLg="3"
                        sizeMd="3"
                        sizeSm="6"
                        sizeXs="12"
                        > */
                  // }
                  return (
                    <MenuItem
                      key={menu.id}
                      shopId={shop.id}
                      menu={menu}
                      // showAddModal={showAddModal}
                    />
                  );
                })}
              </Slider>
            </div>
            {/* </IonRow> */}
            {/* </IonGrid> */}
          </IonList>
        )}
        <br />
        {showContent && (
          <IonList style={{ backgroundColor: 'transparent' }}>
            <ProductsTabs
              title="A la carte"
              products={products}
              showAddModal={showAddModal}
            />
          </IonList>
        )}
        <IonModal
          isOpen={isAddModalVisible}
          swipeToClose
          cssClass={styles.modal}
          onDidDismiss={hideAddModal}
        >
          <div
            style={{
              backgroundImage: `url(${castedProduct.picture?.url})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'top center',
              borderRadius: 0,
              width: '100%',
              height: '30vh',
              maxHeight: '300px',
            }}
          />
          <div className={styles.modalContent}>
            <div>
              <IonText color="dark">
                <h2>
                  {castedProduct.name} -{' '}
                  {castedProduct.price * modalProductQuantity}$
                </h2>
              </IonText>
            </div>
            <br />
            <IonText
              color="dark"
              style={{ overflow: 'scroll', maxHeight: '100px' }}
            >
              {longText || castedProduct.description}
            </IonText>
            <br />
            <div className={styles.buttonContainer}>
              <IonRow class="ion-align-items-center">
                <IonButton
                  color="dark"
                  fill="outline"
                  expand="block"
                  size="small"
                  onClick={() =>
                    modalProductQuantityChanged(modalProductQuantity - 1)
                  }
                  disabled={modalProductQuantity === 1}
                >
                  <IonText>
                    <p>-</p>
                  </IonText>
                </IonButton>
                <IonButton
                  color="dark"
                  expand="block"
                  fill="outline"
                  size="small"
                  className={styles.buttonQuantity}
                >
                  {modalProductQuantity}
                </IonButton>
                <IonButton
                  color="dark"
                  fill="outline"
                  expand="block"
                  size="small"
                  onClick={() =>
                    modalProductQuantityChanged(modalProductQuantity + 1)
                  }
                  disabled={modalProductQuantity === 20}
                >
                  <IonText>
                    <p>+</p>
                  </IonText>
                </IonButton>
              </IonRow>
              <IonRow className="">
                <IonButton
                  onClick={hideAddModal}
                  color="light"
                  expand="block"
                  style={{ flex: 1 }}
                >
                  Cancel
                </IonButton>
                <IonButton
                  onClick={validateAddModal}
                  color="dark"
                  expand="block"
                  style={{ flex: 1 }}
                >
                  Add
                </IonButton>
              </IonRow>
            </div>
          </div>
        </IonModal>
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = (state: IStoreState, ownProps: ShopScreenProps) => {
  const { products, menus, loading, catalogueErrorMessage } = selectShopState(
    state
  );
  const { amount } = selectCartFull(state);

  const { shopId } = ownProps.match.params;
  const shop = selectShop(state, parseInt(shopId)) as IShop;

  return {
    products,
    menus,
    loading,
    catalogueErrorMessage,
    cartAmount: amount,
    shop,
  };
};

const mapDispatchToProps = (dispatch: any, ownProps: ShopScreenProps) => {
  return {
    fetchShopMenusAndProducts: (
      shopId: number,
      stopRefreshIndicator?: Function
    ) => {
      dispatch(
        apiRequest('fetchShopMenusAndProducts', {
          shopId,
          stopRefreshIndicator,
        })
      );
    },
    fetchCart: (stopRefreshIndicator?: Function) => {
      dispatch(apiRequest('fetchCart', { stopRefreshIndicator }));
    },
    addProductToCart: (
      id: number,
      productQuantity: number,
      productPrice: number
    ) => {
      dispatch(
        apiRequest('cartAddProduct', { id, productQuantity, productPrice })
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopScreen);
