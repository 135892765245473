import React from 'react';
import {
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCardContent,
  IonText,
} from '@ionic/react';
import { add } from 'ionicons/icons';
import { IPromotion } from '../../../domains/shop/ShopTypes';
import styles from './PromotionItem.module.scss';
import moment from 'moment';

interface IProps {
  promotion: IPromotion;
}

const PromotionItem: React.FC<IProps> = (props: IProps) => {
  const {
    promotion: {
      id,
      title,
      offer_date_start,
      offer_date_end,
      menu,
      discountPercentage,
    },
    promotion,
  } = props;

  console.log(promotion);

  const startDate = moment(offer_date_start).calendar();
  const endDate = moment(offer_date_end).calendar();

  const menuName = menu?.name;

  return (
    <IonCard className={styles.root} key={id}>
      {/* <img
        className={styles.menuItemImage}
        // src={`${environment.API_ENDPOINT}${picture?.url}`}
        src={pictureUrl}
        alt=""
      /> */}
      <IonCardHeader>
        <IonCardSubtitle>#{id}</IonCardSubtitle>
        <IonCardTitle>{title}</IonCardTitle>
        {/* <IonCardSubtitle>{price}$</IonCardSubtitle> */}
      </IonCardHeader>

      <IonCardContent>
        <IonText>
          <b>Discount</b> {discountPercentage}%
        </IonText>
        <br />
        <IonText>
          <b>Menu</b> {menuName}
        </IonText>
        <br />
        <IonText>
          <b>Starts</b> {startDate}
        </IonText>
        <br />
        <IonText>
          <b>Ends</b> {endDate}
        </IonText>
      </IonCardContent>

      {/* <IonButton
        fill="outline"
        color="dark"
        onClick={onAdd}
        className="ion-float-right ion-margin"
      >
        <IonIcon icon={add} color="dark" />
      </IonButton> */}
    </IonCard>
  );
};

export default PromotionItem;
