import { all, takeEvery, call } from 'redux-saga/effects';
import authUtils from './authUtils';
// import { loginSucceeed, loginFailed, login } from '../domains/auth/AuthActions';
// import {
//   fetchCatalogueSucceeed,
//   fetchCatalogueFailed,
//   fetchCatalogue,
// } from '../domains/shop/ShopActions';
// import authUtils from '../domains/auth/authUtils';

export const storeAuthEmail = (email = '') => {
  window.localStorage.setItem('auth_email', email);
};

export const getAuthEmail = () => window.localStorage.getItem('auth_email');

function* loginSucceeed({ jwt, user }: any) {
  yield call(authUtils.setToken, jwt, true);
  yield call(authUtils.setUserInfo, user, true);
  window.appHistory.replace('/shops');
}

function* loginFailed(action: any) {
  const { errorMessage, isSocial } = action;
  if (isSocial) {
    window.appHistory.push('/auth/login');
  }
  yield true;
}

function* logout() {
  const authEmail = getAuthEmail();
  authUtils.clearAppStorage();
  storeAuthEmail(authEmail);
  window.appHistory.replace('auth/login');
  yield true;
}

const authSagas = function* authSagas(): any {
  yield all([takeEvery('LOGIN_SUCCEEDED', loginSucceeed)]);
  yield all([takeEvery('LOGIN_FAILED', loginFailed)]);
  yield all([takeEvery('LOGOUT', logout)]);
};

export default [authSagas];
