import React from 'react';
import { IonCard, IonCardHeader, IonText, IonRouterLink } from '@ionic/react';
import { IMenu } from '../../../domains/shop/ShopTypes';
import styles from './MenuItem.module.scss';

interface IProps {
  shopId: number;
  menu: IMenu;
  // showAddModal: (menu: IMenu) => any;
  style?: any;
}

const MenuItem: React.FC<IProps> = (props: IProps) => {
  const {
    shopId,
    menu: { id, name, picture },
    // showAddModal,
  } = props;

  // const onAdd = useCallback(() => {
  //   showAddModal(menu);
  // }, [menu, showAddModal]);

  return (
    <IonRouterLink routerLink={`/shops/${shopId}/menu/${id}`}>
      <IonCard className={styles.root} key={id}>
        {/* <img
        className={styles.menuItemImage}
        // src={`${environment.API_ENDPOINT}${picture?.url}`}
        src={pictureUrl}
        alt=""
      /> */}

        <div
          style={{
            backgroundImage: `url(${picture?.url})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'top center',

            borderRadius: 6,
            width: '100%',
            height: 150,
          }}
        />
        <IonCardHeader>
          {/* <IonCardSubtitle>{baseline}</IonCardSubtitle> */}
          {/* <IonCardTitle>
          {name} - {price}€
        </IonCardTitle> */}
          {/* <IonCardSubtitle>{price}$</IonCardSubtitle> */}
          <IonText>{name}</IonText>
        </IonCardHeader>

        {/* <IonCardContent>{description}</IonCardContent> */}

        {/* <IonButton
        fill="outline"
        color="dark"
        onClick={onAdd}
        className="ion-float-right ion-margin"
      >
        <IonIcon icon={add} color="dark" />
      </IonButton> */}
      </IonCard>
    </IonRouterLink>
  );
};

export default MenuItem;
