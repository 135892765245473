import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonRefresherContent,
  IonRefresher,
  IonList,
  IonSpinner,
  IonRow,
  IonText,
  IonButton,
  IonIcon,
  IonButtons,
  IonListHeader,
  IonCardTitle,
} from '@ionic/react';
import { IStoreState } from '../../types/ReduxTypes';
import { apiRequest } from '../../api/ApiActions';
import {
  selectCartFull,
  selectShop,
  selectShops,
  selectShopState,
} from '../../domains/shop/ShopSelectors';
import { CartItemType, ICartFull } from '../../domains/shop/ShopTypes';
import CartItem from '../components/CartItem/CartItem';
import {
  cartSelectionUpdate,
  cartSelectionRemove,
} from '../../domains/shop/ShopActions';
import { basket, checkmark } from 'ionicons/icons';
import { isIOS } from '../../utils/platform';
import MenuSelectionItem from '../components/MenuSelectionItem/MenuSelectionItem';

interface IProps {
  fetchCart: (stopRefreshIndicator?: Function) => any;
  cartFull: ICartFull;
  isCartIsLoading: Boolean;
  cartIsEmpty: Boolean;
  updateSelection: (o: {
    cartItemType: CartItemType;
    id: number;
    value: number;
  }) => any;
  removeSelection: (cartItemType: CartItemType, id: number) => any;
}

const screenTitle = 'Cart';

class CartScreen extends PureComponent<IProps> {
  componentDidMount() {
    this.props.fetchCart();
  }

  resresh = (e: any) => {
    this.props.fetchCart(e.detail.complete);
  };

  render() {
    const {
      cartFull: { amount, productsSelections, menusSelections },
      isCartIsLoading,
      updateSelection,
      removeSelection,
      cartIsEmpty,
    } = this.props;

    const title = (
      <>
        {`${screenTitle} - ${amount || 0}€`}
        {isCartIsLoading && (
          <IonSpinner name="dots" className="ion-margin-start" />
        )}
      </>
    );

    const wrapperTitleInBigHeader = (
      <IonRow className="ion-align-items-center">{title}</IonRow>
    );

    const wrapperTitleInSmallHeader = isIOS ? (
      <IonRow className="ion-align-items-center ion-justify-content-center">
        {title}
      </IonRow>
    ) : (
      title
    );

    const orderButton = isIOS ? (
      <IonButton routerLink="/checkout">
        <IonText>Order</IonText>
      </IonButton>
    ) : (
      <IonButton routerLink="/checkout">
        <IonIcon icon={checkmark} color="dark" />
      </IonButton>
    );

    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonTitle>{wrapperTitleInSmallHeader}</IonTitle>
            {!cartIsEmpty && <IonButtons slot="end">{orderButton}</IonButtons>}
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonHeader collapse="condense">
            <IonToolbar>
              <IonTitle size="large">{wrapperTitleInBigHeader}</IonTitle>
            </IonToolbar>
          </IonHeader>

          <IonRefresher slot="fixed" onIonRefresh={this.resresh}>
            <IonRefresherContent />
          </IonRefresher>
          {!isCartIsLoading && !!menusSelections?.length && (
            <IonList style={{ backgroundColor: 'transparent' }}>
              {/* <IonListHeader>We have products that fit</IonListHeader> */}
              <IonListHeader>
                <IonCardTitle>Menus</IonCardTitle>
              </IonListHeader>
              {menusSelections?.map((menuSelection) => (
                <MenuSelectionItem
                  key={menuSelection.id}
                  menuSelection={menuSelection}
                  // updateSelection={updateSelection}
                  removeSelection={removeSelection}
                />
              ))}
            </IonList>
          )}
          {!isCartIsLoading && !!productsSelections?.length && (
            <IonList style={{ backgroundColor: 'transparent' }}>
              {/* <IonListHeader>We have products that fit</IonListHeader> */}
              <IonListHeader>
                <IonCardTitle>A la carte</IonCardTitle>
              </IonListHeader>
              {productsSelections?.map((productSelec) => (
                <CartItem
                  key={productSelec.id}
                  cartSelection={productSelec}
                  updateSelection={updateSelection}
                  removeSelection={removeSelection}
                />
              ))}
            </IonList>
          )}
          {!isCartIsLoading && cartIsEmpty && (
            <div className="container">
              <IonText>
                <h2>Cart is empty</h2>
              </IonText>
              <IonButton routerLink="/shops" color="dark">
                <IonIcon
                  icon={basket}
                  style={{ marginRight: '10px' }}
                  size="small"
                />{' '}
                <IonText>
                  <p>Find something good</p>
                </IonText>
              </IonButton>
            </div>
          )}

          {isCartIsLoading && (
            <div className="container">
              <IonSpinner />
            </div>
          )}
        </IonContent>
      </IonPage>
    );
  }
}

const mapStateToProps = (state: IStoreState) => {
  const cartFull = selectCartFull(state);
  const { isCartIsLoading } = selectShopState(state);

  const cartIsEmpty =
    !cartFull.productsSelections?.length && !cartFull.menusSelections?.length;

  return {
    cartFull,
    isCartIsLoading,
    cartIsEmpty,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    fetchCart: (stopRefreshIndicator?: Function) => {
      dispatch(apiRequest('fetchCart', { stopRefreshIndicator }));
    },
    updateSelection: ({
      cartItemType,
      id,
      value,
    }: {
      cartItemType: CartItemType;
      id: number;
      value: number;
    }) => {
      dispatch(cartSelectionUpdate({ cartItemType, id, value }));
    },
    removeSelection: (cartItemType: CartItemType, id: number) => {
      dispatch(cartSelectionRemove({ cartItemType, id }));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CartScreen);
