import { all, takeEvery, put } from 'redux-saga/effects';
import { apiRequest } from '../../api/ApiActions';
// import authUtils from './authUtils';

function* cartSelectionUpdate({ id, value }: any) {
  yield put(
    apiRequest('cartSelectionUpdate', {
      id,
      value,
    })
  );
}

function* cartSelectionRemove({ cartItemType, id }: any) {
  yield put(
    apiRequest('cartSelectionRemove', {
      cartItemType,
      id,
    })
  );
}

const shopSagas = function* apiSagas(): any {
  yield all([takeEvery('CART_SELECTION_UPDATE', cartSelectionUpdate)]);
  yield all([takeEvery('CART_SELECTION_REMOVE', cartSelectionRemove)]);
};

export default [shopSagas];
