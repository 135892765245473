import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonText,
  IonSpinner,
  IonList,
  IonRefresher,
  IonRefresherContent,
  IonButtons,
} from '@ionic/react';
import { IProduct, IPromotions } from '../../../domains/shop/ShopTypes';
import { selectShopState } from '../../../domains/shop/ShopSelectors';
import { IStoreState } from '../../../types/ReduxTypes';
import { apiRequest } from '../../../api/ApiActions';
import PromotionItem from '../../components/PromotionItem/PromotionItem';
import HeaderCartButton from '../../components/HeaderCartButton/HeaderCartButton';

interface IState {
  isAddModalVisible: boolean;
  modalProduct: {} | IProduct;
  modalProductQuantity: number;
}

interface IProps {
  promotions: IPromotions;
  fetchPromotions: (stopRefreshIndicator?: Function) => any;
  loading: boolean;
  errorMessage: string;
}

const screenTitle = 'Promotions';

class PromotionsScreen extends PureComponent<IProps, IState> {
  componentDidMount() {
    this.props.fetchPromotions();
  }

  resresh = (e: any) => {
    this.props.fetchPromotions(e.detail.complete);
  };

  render() {
    const { promotions, loading, errorMessage } = this.props;

    const showLoader = loading;
    const showErrorMessage = !showLoader && !!errorMessage;
    const showList = !showLoader && !showErrorMessage;

    // TODO Loic: make modalProduct aquals to "null" or "IProduct", and use it in the view
    // const castedProduct = modalProduct as IProduct;

    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonTitle>{screenTitle}</IonTitle>
            <IonButtons slot="end">
              <HeaderCartButton />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonHeader collapse="condense">
            <IonToolbar>
              <IonTitle size="large">{screenTitle}</IonTitle>
            </IonToolbar>
          </IonHeader>

          <IonRefresher slot="fixed" onIonRefresh={this.resresh}>
            <IonRefresherContent />
          </IonRefresher>

          {!showList && (
            <div className="container">
              {showLoader && <IonSpinner />}
              {showErrorMessage && (
                <IonText color="danger">{errorMessage}</IonText>
              )}
            </div>
          )}
          {showList && (
            <IonList style={{ backgroundColor: 'transparent' }}>
              {/* <IonListHeader>
                <IonText>
                  <h2>Main courses</h2>
                </IonText>
              </IonListHeader> */}
              {promotions?.map((promotion) => {
                return (
                  <PromotionItem key={promotion.id} promotion={promotion} />
                );
              })}
            </IonList>
          )}
        </IonContent>
      </IonPage>
    );
    // );
  }
}

const mapStateToProps = (state: IStoreState, ownProps: IProps) => {
  const {
    promotions,
    isPromotionsLoading,
    promotionsErrorMessage,
  } = selectShopState(state);
  return {
    promotions,
    loading: isPromotionsLoading,
    errorMessage: promotionsErrorMessage,
  };
};

const mapDispatchToProps = (dispatch: any, ownProps: IProps) => {
  return {
    fetchPromotions: (stopRefreshIndicator?: Function) => {
      dispatch(
        apiRequest('fetchPromotions', {
          stopRefreshIndicator,
        })
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PromotionsScreen);
