/**
 * Cart
 */

import { CartItemType } from './ShopTypes';

export const cartSelectionUpdate = ({
  id,
  value,
  cartItemType,
}: {
  cartItemType: CartItemType;
  id: number;
  value: number;
}) => ({
  type: 'CART_SELECTION_UPDATE',
  cartItemType,
  id,
  value,
});

export const cartSelectionRemove = ({
  cartItemType,
  id,
}: {
  cartItemType: CartItemType;
  id: number;
}) => ({
  type: 'CART_SELECTION_REMOVE',
  cartItemType,
  id,
});
