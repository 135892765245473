import React, { useCallback } from 'react';
import cx from 'classnames';
import {
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCardContent,
  IonButton,
  IonIcon,
  IonText,
  IonRow,
  IonCol,
} from '@ionic/react';
import { add } from 'ionicons/icons';
import { IProduct } from '../../../domains/shop/ShopTypes';
import styles from './ProductItem.module.scss';
import { isIOS } from '../../../utils/platform';

interface IProps {
  product: IProduct;
  showAddModal?: (product: IProduct) => any;
  style?: any;
}

const ProductItem: React.FC<IProps> = (props: IProps) => {
  const {
    product: { id, baseline, name, description, picture, price },
    product,
    showAddModal,
    style,
  } = props;

  const onAdd = useCallback(() => {
    if (typeof showAddModal === 'function') {
      showAddModal(product);
    }
  }, [product, showAddModal]);

  return (
    <IonCard
      key={id}
      className={cx(styles.root, isIOS ? styles.rootIOS : styles.rootMd)}
      onClick={onAdd}
      style={style}
    >
      <IonRow>
        {/* <img
        className={styles.menuItemImage}
        // src={`${environment.API_ENDPOINT}${picture?.url}`}
        src={pictureUrl}
        alt=""
      /> */}

        <div
          style={{
            backgroundImage: `url(${picture?.url})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'top center',
            borderRadius: 0,
            width: 70,
            height: 70,
          }}
        />
        <IonCol
          style={{
            flex: 1,
            paddingLeft: 12,
            marginTop: '6px',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          <IonText
            style={{
              fontWeight: '500',
              fontSize: 18,
              marginTop: '2px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {name}
          </IonText>
          <IonText
            style={{
              opacity: 0.8,
              marginTop: '4px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {baseline}
          </IonText>
        </IonCol>
        <IonCol style={{ flex: 0, padding: '6px 12px 0 6px' }}>
          <IonText
            style={{
              fontWeight: '500',
              fontSize: 18,
              whiteSpace: 'nowrap',
              opacity: 0.7,
            }}
          >
            {price} €
          </IonText>
        </IonCol>

        {/* <IonCardContent>{description}</IonCardContent> */}

        {/* <IonButton
        fill="outline"
        color="dark"
        onClick={onAdd}
        className="ion-float-right ion-margin"
      >
        <IonIcon icon={add} color="dark" />
      </IonButton> */}
      </IonRow>
    </IonCard>
  );
};

export default ProductItem;
