import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
const moment = extendMoment(Moment);

export type DaysSLotsType = Array<{
  value: string;
  label: string;
}>;

export type TimeSLotsType = Array<{
  value: string;
  label: string;
}>;

const calendarRule = {
  sameDay: '[Today]',
  nextDay: '[Tomorrow]',
  nextWeek: 'dddd',
  lastDay: '[Yesterday]',
  lastWeek: '[Last] dddd',
  sameElse: 'dddd Do',
};

const roundToNextMinute = (date: string) => {
  return moment(date).startOf('minute').add(1, 'minute').toISOString();
};

const roundToTheNext10minutes = (date: string) => {
  let dateNext10MinRange = date;
  let minutes = new Date(dateNext10MinRange).getMinutes();
  let is10range = !(minutes % 10);

  while (!is10range) {
    dateNext10MinRange = roundToNextMinute(dateNext10MinRange);
    minutes = new Date(dateNext10MinRange).getMinutes();
    is10range = !(minutes % 10);
  }

  const finalDate = moment(dateNext10MinRange)
    .set('minute', minutes)
    .toISOString();
  return finalDate;
};

export const getDaysSlots: () => DaysSLotsType = () => {
  const startDate = new Date();
  const endDate = moment(startDate).add(1, 'week');
  const range = moment.range(startDate, endDate);
  const days = Array.from(range.by('days', { step: 1, excludeEnd: false }));
  if (days?.length) {
    const formattedDays = days.map((m) => {
      return {
        value: m.toISOString().substr(0, 10), // '2021-09-17'
        label: m.calendar(calendarRule), // custom label
      };
    });
    return formattedDays;
  }
  return [];
};

export const getTimeSlots: () => TimeSLotsType = () => {
  const startDate = roundToTheNext10minutes(new Date().toISOString());
  const endDate = moment(startDate).add(3, 'hours');

  const range = moment.range(new Date(startDate), endDate);
  const minutes = Array.from(
    range.by('minutes', { step: 10, excludeEnd: false })
  );
  if (minutes?.length) {
    const formattedHours = minutes.map((m) => {
      return {
        value: m.format('HH:mm'),
        label: m.format('HH:mm'),
      };
    });
    return formattedHours;
  }
  return [];
};
