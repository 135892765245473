import { IAuthState } from './AuthTypes';
import { REHYDRATE } from 'redux-persist';

export const INITIAL_STATE: IAuthState = {
  authentificated: false,
  jwt: '',
  user: {
    id: 0,
    email: '',
    username: '',
    blocked: false,
    confirmed: false,
    avatarUrl: '',
    created_at: '',
    provider: 'local',
    role: {
      id: 0,
      name: '',
      description: '',
      type: '',
    },
    updated_at: 'string',
    carts: [],
    cart: {
      id: 0,
      user: 0, // user id
      amount: 0,
      created_at: '',
      updated_at: 'string',
    },
  },
  loading: false,
  errorMessage: '',
};

export default (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case REHYDRATE: {
      const { auth = {} } = action?.payload || {};
      return {
        ...INITIAL_STATE,
        authentificated: auth.authentificated,
        jwt: auth.jwt,
        user: auth.user,
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        authentificated: INITIAL_STATE.authentificated,
        user: INITIAL_STATE.user,
        loading: INITIAL_STATE.loading,
        errorMessage: INITIAL_STATE.errorMessage,
      };
    }
    case 'LOGIN': {
      return {
        ...state,
        loading: true,
        errorMessage: '',
      };
    }
    case 'LOGIN_SUCCEEDED': {
      return {
        ...state,
        authentificated: true,
        jwt: action.jwt,
        user: action.user,
        loading: false,
        errorMessage: '',
      };
    }
    case 'LOGIN_FAILED': {
      return {
        ...state,
        authentificated: false,
        loading: false,
        errorMessage: action.errorMessage || '',
      };
    }
    default:
      return state;
  }
};
