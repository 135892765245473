export const INITIAL_STATE = {
  cart: {
    total: 0,
    totalDisplay: '',
    items: [],
  },
  orders: [],
  addresses: [],
  carriers: [],
};

export default (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case 'LOGOUT': {
      return {
        ...state,
        cart: INITIAL_STATE.cart,
        orders: INITIAL_STATE.orders,
        addresses: INITIAL_STATE.addresses,
        carriers: INITIAL_STATE.carriers,
      };
    }
    case 'LOGIN': {
      return {
        ...state,
      };
    }
    case 'LOGIN_SUCCEEDED': {
      return {
        ...state,
        // cart: action.cart,
        // orders: action.orders,
        // addresses: action.addresses,
        // carriers: action.carriers,
      };
    }
    case 'LOGIN_FAILED': {
      return {
        ...state,
        cart: INITIAL_STATE.cart,
        orders: INITIAL_STATE.orders,
        addresses: INITIAL_STATE.addresses,
        carriers: INITIAL_STATE.carriers,
      };
    }

    default:
      return state;
  }
};
