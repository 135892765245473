import React from 'react';
import { IonButton, IonIcon, IonBadge, IonSpinner } from '@ionic/react';
import { cart } from 'ionicons/icons';
import styles from './HeaderCartButton.module.scss';
import {
  selectCartFull,
  selectShopState,
} from '../../../domains/shop/ShopSelectors';
import { IStoreState } from '../../../types/ReduxTypes';
import { connect } from 'react-redux';

interface StateProps {
  cartAmount: number;
  isCartLoading: Boolean;
}

interface DispatchProps {}

interface OwnProps {}

type Props = StateProps & DispatchProps & OwnProps;

const HeaderCartButton: React.FC<Props> = (props: Props) => {
  const { cartAmount, isCartLoading } = props;

  if (isCartLoading) {
    return <IonSpinner name="dots" className={styles.loader} />;
  }

  return (
    <IonButton
      routerLink="/cart"
      routerDirection="root"
      className={styles.root}
    >
      <IonIcon icon={cart} color="medium" />
      <IonBadge color="medium">{cartAmount || 0}$</IonBadge>
    </IonButton>
  );
};

const mapStateToProps = (state: IStoreState, ownProps: OwnProps) => {
  const { amount } = selectCartFull(state);

  const { isCartIsLoading } = selectShopState(state);

  return {
    cartAmount: amount,
    isCartLoading: isCartIsLoading,
  };
};

export default connect(mapStateToProps)(HeaderCartButton);
