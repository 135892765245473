import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonText,
  IonSpinner,
  IonList,
  IonButtons,
  IonButton,
  IonRefresher,
  IonRefresherContent,
  IonRow,
  IonGrid,
  IonCol,
  IonBackButton,
  IonCardTitle,
  IonCardSubtitle,
  IonIcon,
} from '@ionic/react';
import {
  IProduct,
  IShop,
  IMenu,
  MenuSelection,
} from '../../../domains/shop/ShopTypes';
import {
  selectShopState,
  selectShop,
  selectCartFull,
} from '../../../domains/shop/ShopSelectors';
import { IStoreState } from '../../../types/ReduxTypes';
import { apiRequest } from '../../../api/ApiActions';
import { RouteComponentProps } from 'react-router';
import HeaderCartButton from '../../components/HeaderCartButton/HeaderCartButton';
import ProductsList from '../ShopScreen/ProductsList';
import VerticalLinearStepper from './MenuSelectorStepper';
import { basket } from 'ionicons/icons';

interface IState {
  isAddModalVisible: boolean;
  modalProduct: {} | IProduct;
  modalProductQuantity: number;
}

interface Props
  extends RouteComponentProps<{
    shopId: string;
    menuId: string;
  }> {
  menu: IMenu | undefined;
  fetchShopMenusAndProducts: (
    id: number,
    stopRefreshIndicator?: Function
  ) => any;
  fetchCart: (stopRefreshIndicator?: Function) => any;
  addProductToCart: (productId: number, quantity: number, price: number) => any;
  addMenuToCart: (
    menuId: number,
    price: number,
    selection: MenuSelection
  ) => any;
  loading: boolean;
  catalogueErrorMessage?: string;
  cartAmount?: number;
  shop: IShop;
}

const MenuScreen: React.FC<Props> = (props) => {
  const {
    menu,
    loading,
    catalogueErrorMessage,
    shop,
    fetchShopMenusAndProducts,
    fetchCart,
    addProductToCart,
    addMenuToCart,
    match,
  } = props;

  const [isSelectorVisible, setIsSelectorVisible] = useState(false);
  // const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  // const [modalProduct, setModalProduct] = useState({});
  // const [modalProductQuantity, setModalProductQuantity] = useState(0);

  const resresh = useCallback(
    (e: any) => {
      const id = parseInt(match.params.shopId);
      fetchShopMenusAndProducts(id, e.detail.complete);
      fetchCart(e.detail.complete);
    },
    [fetchCart, fetchShopMenusAndProducts, match.params.shopId]
  );

  const openSelector = useCallback(() => {
    setIsSelectorVisible(true);
  }, []);

  const closeSelector = useCallback(() => {
    setIsSelectorVisible(false);
  }, []);

  const onSelectionValidated = useCallback(
    (selection: MenuSelection, handleReset: Function) => {
      if (!menu) {
        // only needed for typescript
        return;
      }

      addMenuToCart(menu.id, menu.price, selection);

      closeSelector();
      handleReset();
    },
    [menu, addMenuToCart, closeSelector]
  );

  // did mount
  useEffect(() => {
    const id = parseInt(match.params.shopId);
    fetchShopMenusAndProducts(id);
    fetchCart();
  }, []);

  const showLoader = loading;
  const showErrorMessage = !showLoader && !!catalogueErrorMessage;

  const showList = !showLoader && !showErrorMessage;

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/shops" />
          </IonButtons>
          <IonTitle>{`${shop.name} - ${menu?.name}`}</IonTitle>

          <IonButtons slot="end">
            <HeaderCartButton />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {/* <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{shop.name}</IonTitle>
          </IonToolbar>
        </IonHeader> */}

        <IonRefresher slot="fixed" onIonRefresh={resresh}>
          <IonRefresherContent />
        </IonRefresher>

        <div className="ion-padding">
          <IonGrid>
            <IonRow>
              <IonCol style={{ flex: 0 }}>
                <div
                  style={{
                    backgroundImage: `url(${menu?.picture?.url})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'top center',

                    borderRadius: 6,
                    width: 80,
                    height: 80,
                  }}
                />
              </IonCol>
              <IonCol style={{ flex: 1, paddingTop: 8 }}>
                <IonCardSubtitle>{menu?.price}€</IonCardSubtitle>
                <IonCardTitle style={{ fontSize: 20 }}>
                  {menu?.name}
                </IonCardTitle>
                <IonButton onClick={openSelector} color="dark" size="small">
                  <IonIcon
                    icon={basket}
                    style={{ marginRight: '10px' }}
                    size="small"
                  />{' '}
                  <IonText>
                    <p>Add to cart</p>
                  </IonText>
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>

        {!showList && (
          <div className="container">
            {showLoader && <IonSpinner />}
            {showErrorMessage && (
              <IonText color="danger">{catalogueErrorMessage}</IonText>
            )}
          </div>
        )}
        {showList && (
          <IonList style={{ backgroundColor: 'transparent' }}>
            {/* <IonListHeader>
              <IonCardTitle>A la carte</IonCardTitle>
            </IonListHeader> */}
            {!!menu?.starters.length && (
              <ProductsList title="Starters" products={menu?.starters || []} />
            )}
            {!!menu?.mainCourses.length && (
              <ProductsList
                title="Main courses"
                products={menu?.mainCourses || []}
              />
            )}
            {!!menu?.desserts.length && (
              <ProductsList title="Desserts" products={menu?.desserts || []} />
            )}
          </IonList>
        )}
        {/* <IonModal
          isOpen={isAddModalVisible}
          swipeToClose
          cssClass={styles.modal}
          onDidDismiss={hideAddModal}
        >
          <div className={styles.modalContent}>
            <div>
              <IonText color="dark">
                <h2>{castedProduct.name}</h2>
              </IonText>
              <IonText color="dark">
                <h2>{castedProduct.price * modalProductQuantity}$</h2>
              </IonText>
              <br />
              <br />
            </div>
            <div className={styles.buttonContainer}>
              <IonRow class="ion-align-items-center">
                <IonButton
                  color="dark"
                  fill="outline"
                  expand="block"
                  onClick={() =>
                    modalProductQuantityChanged(modalProductQuantity - 1)
                  }
                  disabled={modalProductQuantity === 1}
                >
                  <IonText>
                    <p>-</p>
                  </IonText>
                </IonButton>
                <IonButton
                  color="dark"
                  expand="block"
                  fill="outline"
                  className={styles.buttonQuantity}
                >
                  {modalProductQuantity}
                </IonButton>
                <IonButton
                  color="dark"
                  fill="outline"
                  expand="block"
                  onClick={() =>
                    modalProductQuantityChanged(modalProductQuantity + 1)
                  }
                  disabled={modalProductQuantity === 20}
                >
                  <IonText>
                    <p>+</p>
                  </IonText>
                </IonButton>
              </IonRow>
              <IonButton onClick={validateAddModal} color="dark" expand="block">
                Add
              </IonButton>
              <IonButton onClick={hideAddModal} color="light" expand="block">
                Cancel
              </IonButton>
            </div>
          </div>
        </IonModal> */}
        <VerticalLinearStepper
          isOpen={isSelectorVisible}
          hide={closeSelector}
          onValidate={onSelectionValidated}
          menuName={menu?.name || ''}
          price={menu?.price || 0}
          starters={menu?.starters || []}
          mainCourses={menu?.mainCourses || []}
          desserts={menu?.desserts || []}
          starterMandatory={menu?.starterMandatory || false}
          mainCourseMandatory={menu?.mainCourseMandatory || false}
          dessertMandatory={menu?.dessertMandatory || false}
        />
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = (state: IStoreState, ownProps: Props) => {
  const { menus } = selectShopState(state);
  const { amount } = selectCartFull(state);

  const { shopId, menuId } = ownProps.match.params;
  const shop = selectShop(state, parseInt(shopId)) as IShop;

  const menu = menus.find((m) => m.id === parseInt(menuId));

  return {
    menu,
    shop,
  };
};

const mapDispatchToProps = (dispatch: any, ownProps: Props) => {
  return {
    fetchShopMenusAndProducts: (
      shopId: number,
      stopRefreshIndicator?: Function
    ) => {
      dispatch(
        apiRequest('fetchShopMenusAndProducts', {
          shopId,
          stopRefreshIndicator,
        })
      );
    },
    fetchCart: (stopRefreshIndicator?: Function) => {
      dispatch(apiRequest('fetchCart', { stopRefreshIndicator }));
    },
    addProductToCart: (
      id: number,
      productQuantity: number,
      productPrice: number
    ) => {
      dispatch(
        apiRequest('cartAddProduct', { id, productQuantity, productPrice })
      );
    },
    addMenuToCart: (id: number, price: number, selection: MenuSelection) => {
      dispatch(apiRequest('cartAddMenu', { id, price, selection }));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuScreen);
