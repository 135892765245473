import { isPlatform } from '@ionic/react';

const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');

const setApparenceListerner = () => {
  // Listen for changes to the prefers-color-scheme media query
  prefersDark.addListener((mediaQuery) => toggleDarkTheme(mediaQuery.matches));
};

const toggleDarkTheme = (shouldAdd: boolean) =>
  document.body.classList.toggle('dark', shouldAdd);

/**
 * Dark mode is automatic ionly on mobile, from the device preferences
 */
export const setInitialApparenceMode = () => {
  if (isPlatform('capacitor')) {
    toggleDarkTheme(prefersDark.matches);
    setApparenceListerner();
  } else {
    toggleDarkTheme(false);
  }
};
