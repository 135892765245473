import React, { PureComponent } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import {
  IonInput,
  IonItem,
  IonButton,
  IonSpinner,
  IonText,
} from '@ionic/react';
import { storeAuthEmail } from '../../../../domains/auth/AuthSagas';

const MyInput = ({ field, form, ...props }: any) => {
  return <IonInput {...field} {...props} onIonChange={field.onChange} />;
};

type Values = {
  username: string;
  email: string;
  password: string;
  passwordConfirm: string;
};

interface IProps {
  onSubmit: (values: Values) => any;
  showLoader: boolean;
}

class RegisterForm extends PureComponent<IProps> {
  validate = (values: Values) => {
    const errors: { [key: string]: string } = {};

    if (!values.username?.trim()) {
      errors.username = 'Required';
    }

    if (!values.email) {
      errors.email = 'Required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = 'Invalid email address';
    }

    if (!values.password) {
      errors.password = 'Required';
    }
    if (!values.passwordConfirm) {
      errors.passwordConfirm = 'Required';
    }
    if (
      values.password &&
      values.passwordConfirm &&
      values.password !== values.passwordConfirm
    ) {
      errors.passwordConfirm = "Don't match with 'password'";
    }
    return errors;
  };

  onSubmit = (values: Values, { resetForm }: any) => {
    storeAuthEmail(values.email);
    this.props.onSubmit(values);
    resetForm();
  };

  render() {
    const { showLoader } = this.props;
    return (
      <div className="LoginForm-container">
        <Formik
          initialValues={{
            username: '',
            email: '',
            password: 'password',
            passwordConfirm: 'password',
          }}
          //   initialValues={{
          //     username: '',
          //     email: '',
          //     password: '',
          //     passwordConfirm: '',
          //   }}
          validate={this.validate}
          onSubmit={this.onSubmit}
        >
          {({ isSubmitting, submitCount, resetForm }) => (
            <Form>
              <IonItem>
                <Field
                  type="username"
                  name="username"
                  placeholder="Username"
                  component={MyInput}
                />
                <ErrorMessage name="username">
                  {(msg) =>
                    !!submitCount && (
                      <>
                        <IonText color="danger">{msg}</IonText>
                      </>
                    )
                  }
                </ErrorMessage>
              </IonItem>

              <IonItem>
                <Field
                  type="email"
                  name="email"
                  component={MyInput}
                  placeholder="Email"
                />
                <ErrorMessage name="email">
                  {(msg) =>
                    !!submitCount && (
                      <>
                        <IonText color="danger">{msg}</IonText>
                      </>
                    )
                  }
                </ErrorMessage>
              </IonItem>

              <IonItem>
                <Field
                  type="password"
                  name="password"
                  component={MyInput}
                  placeholder="Password"
                />
                <ErrorMessage name="password">
                  {(msg) =>
                    !!submitCount && (
                      <>
                        <IonText color="danger">{msg}</IonText>
                      </>
                    )
                  }
                </ErrorMessage>
              </IonItem>

              <IonItem>
                <Field
                  type="password"
                  name="passwordConfirm"
                  component={MyInput}
                  placeholder="Confirm password"
                />
                <ErrorMessage name="passwordConfirm">
                  {(msg) =>
                    !!submitCount && (
                      <>
                        <IonText color="danger">{msg}</IonText>
                      </>
                    )
                  }
                </ErrorMessage>
              </IonItem>
              <br />
              <br />
              <IonButton
                type="submit"
                disabled={isSubmitting || showLoader}
                color="dark"
              >
                {showLoader ? <IonSpinner /> : 'Sign Up'}
              </IonButton>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

export default RegisterForm;
