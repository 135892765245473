import React from 'react';
// import { CardElement, CardNumberElement } from 'react-stripe-elements';
// import { injectStripe } from 'react-stripe-elements';
import { IonButton, IonCardSubtitle, IonSpinner } from '@ionic/react';
import {
  CardElement,
  // Elements,
  // PaymentRequestButtonElement,
  // useElements,
  // useStripe,
} from '@stripe/react-stripe-js';

// interface InjectedStripeProps {
//   stripe: any;
//   elements: stripe.elements.Elements | null;
// }

type IProps = {
  amount: number;
  zipcode: string;
  isLoading: boolean;
  handleSubmit: (e: any) => any;
};

class CardSection extends React.Component<IProps, any> {
  render() {
    const { amount, zipcode, isLoading, handleSubmit } = this.props;
    return (
      <div>
        <div>
          <IonCardSubtitle>Credit or debit card</IonCardSubtitle>
          <hr />
          {/* <label htmlFor="card-element">Credit or debit card</label> */}

          <div>
            <fieldset style={{ border: 'none' }}>
              <div className="form-row">
                <div id="card-element" style={{ width: '100%' }}>
                  <CardElement
                    options={{
                      value: {
                        postalCode: zipcode,
                      },
                    }}
                    // style={{ width: '100%', base: { fontSize: '18px' } }}
                  />
                </div>
                <br />
                <br />
                <div>
                  <IonButton
                    className="order-button-wrapper"
                    disabled={isLoading}
                    onClick={handleSubmit}
                  >
                    {isLoading ? <IonSpinner /> : `Pay ${amount}€`}
                  </IonButton>
                </div>
                {/* {this.props.stripeError ? (
                  <div>{this.props.stripeError.toString()}</div>
                ) : null} */}
                <div id="card-errors" role="alert" />
              </div>
            </fieldset>
          </div>
        </div>
      </div>
    );
  }
}
// export default injectStripe(CardSection);
export default CardSection;
